
import {Type} from "class-transformer";
import {ColladaSceneContent, ColladaSceneEnvironmentDTO, ColladaShaderDTO} from "@engview/lib-icommon";

class EditorData {
    @Type(() => ColladaSceneEnvironmentDTO)
    public environment!: ColladaSceneEnvironmentDTO;
    @Type(() => ColladaShaderDTO)
    public shaders!: ColladaShaderDTO[];
    @Type(() => ColladaSceneContent)
    public objects!: ColladaSceneContent;
}

export {EditorData}