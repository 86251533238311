
enum ProcessingParamType {
    RGBCOLOR = "RGBCOLOR",
    SCALAR = "SCALAR"
}

enum ProcessingType {
    PAPER_FACE = "PAPER_FACE",
    PAPER_EDGE = "PAPER_EDGE",
    BACKING = "BACKING",
    LAMINATION = "LAMINATION",
    PRINTING = "PRINTING",
    FOILING = "FOILING",
    VARNISHING = "VARNISHING",
    IMPRINT = "IMPRINT"
}

enum Resolution {
    MIN = 7, //2^7 = 128
    LOW = 10, //2^10 = 1024
    MEDIUM = 11, //2^11 = 2048
    HIGH = 12, //2^12 = 4096
    EXTRA_HIGH = 13 //2^13 = 8192
}

class ProcessingParamInfo {
    public descr!: string;
    public id!: string;
    public name!: string;
    public type!: ProcessingParamType;
    public defValueScalar!: number;
    public defValueColor!: string;
    public scalarValueRangeMin!: number;
    public scalarValueRangeMax!: number;
    public scalarValueInits!: string;
}

class ProcessingInfo {
    public descr!: string;
    public params!: ProcessingParamInfo[];
    public id!: string;
    public name!: string;
    public type!: ProcessingType;
}

export {ProcessingInfo, ProcessingParamInfo, ProcessingType, ProcessingParamType, Resolution};