enum AnimationError {
    NO_ANIMATION = 0,
    NO_NEXT_STEP = 1,
    NO_PREV_STEP = 2,
    NO_NEXT_STAGE = 3,
    NO_PREV_STAGE = 4,
    INTERRUPTED = 5,
    TIME_OUT_OF_BOUNDS = 6
}

export {AnimationError}