import {OutputType} from "./OutputType";
import {FormatType} from "./FormatType";
import {BaseOutput3DPdf} from "./BaseOutput3DPdf";
import type {IOutputRequestPrev} from "./IOutputRequestPrev";

class OutputUSDZ extends BaseOutput3DPdf implements IOutputRequestPrev {
    constructor();
    constructor(other: OutputUSDZ);
    constructor(drawingId?: number | null);
    constructor(...arr: any[]) {
        if(arr.length == 0){
            super(OutputType.USDZ, FormatType.USDZ, null);
        }
        else if (arr.length === 1) {
            if(arr[0] instanceof OutputUSDZ){
                const other: OutputUSDZ = arr[0];
                super(other);
            }
            else{
                super(OutputType.USDZ, FormatType.USDZ, arr[0]);
            }
        }
    }
}

export {OutputUSDZ}
