import {OutputType} from "../../helper/outputs/impl/OutputType";
import {FormatType} from "../../helper/outputs/impl/FormatType";

class DieBoardChaseHolesInfo {

    private useChaseholes: boolean = false;
    private numHoles: number = 0;
    private minDistToCut: number = 0;

    constructor();
    constructor(orig: DieBoardChaseHolesInfo)
    constructor(...arr: any[]) {
        if(arr.length == 1 && arr[0] instanceof DieBoardChaseHolesInfo){
            const orig: DieBoardChaseHolesInfo = arr[0];
            this.useChaseholes = orig.useChaseholes;
            this.numHoles = orig.numHoles;
            this.minDistToCut = orig.minDistToCut;
        }
    }

    public useChaseHoles(): boolean {
        return this.useChaseholes;
    }

    public setUseChaseHoles(useChaseholes: boolean): void {
        this.useChaseholes = useChaseholes;
    }

    public getNumHoles(): number {
        return this.numHoles;
    }

    public setNumHoles(numHoles: number) {
        this.numHoles = numHoles;
    }

    public getMinDistToCut(): number {
        return this.minDistToCut;
    }

    public setMinDistToCut(minDistToCut: number) {
        this.minDistToCut = minDistToCut;
    }
}

export {DieBoardChaseHolesInfo}