import {DrawingSetType} from "../../../cadoverride/utils/DrawingSetType";
import type {OutputType} from "./OutputType";
import type {FormatType} from "./FormatType";

abstract class OutputRequest{
    public mapID!: string;
    public type!: OutputType;
    public format!: FormatType;
    public drawingSetType:DrawingSetType = DrawingSetType.DrawingById;
    public drawingId!: number | null;
    // The expected mime type coming in from cad to the resource server (f.e. to indicate zae)
    public mimeType!: string;
    public temporary!: boolean;
    public secure!: boolean;
    public alpha!: number;

    constructor(other: OutputRequest);
    constructor(type?: OutputType, format?: FormatType, drawingId?: number | null);
    constructor(...arr: any[]) {
        if(arr.length == 1){
            const other: OutputRequest = arr[0];
            this.type = other.type;
            this.format = other.format;
            this.drawingId = other.drawingId;
            this.alpha = other.alpha;
        }
        else if(arr.length ===3) {
            this.type = arr[0];
            this.format = arr[1];
            this.drawingId = arr[2];
            this.temporary = false;
            this.secure = false;
        }
    }
}

export {OutputRequest}