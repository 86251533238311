import {OutputType} from "./OutputType";
import {FormatType} from "./FormatType";
import {OutputRequest} from "./OutputRequest";
import {Type} from "class-transformer";
import {OutputBumpData} from "./OutputBumpData";
import type {IOutputRequestPrev} from "./IOutputRequestPrev";

class OutputBump extends OutputRequest implements IOutputRequestPrev {

    @Type(() => OutputBumpData)
    private frontData!: OutputBumpData[];
    @Type(() => OutputBumpData)
    private rearData!: OutputBumpData[];
    private width!: number;
    private height!: number;
    private params!: string;
    private includeCutOutlines!: boolean;

    constructor();
    constructor(drawingId?: number | null);
    constructor(...arr: any[]) {
        if(arr.length == 0){
            super(OutputType.BUMP, FormatType.PNG, null);
        }
        else if (arr.length === 1) {
            super(OutputType.BUMP, FormatType.PNG, arr[0]);
        }
    }


    public getFrontData(): OutputBumpData[] {
        return this.frontData;
    }

    public setFrontData(value: OutputBumpData[]): void {
        this.frontData = value;
    }

    public getRearData(): OutputBumpData[] {
        return this.rearData;
    }

    public setRearData(value: OutputBumpData[]) {
        this.rearData = value;
    }

    public getWidth(): number {
        return this.width;
    }

    public setWidth(value: number): void {
        this.width = value;
    }

    public getHeight(): number {
        return this.height;
    }

    public setHeight(value: number): void {
        this.height = value;
    }

    public getParams(): string {
        return this.params;
    }

    public setParams(value: string): void {
        this.params = value;
    }

    public isIncludeCutOutlines(): boolean {
        return this.includeCutOutlines;
    }

    public setIncludeCutOutlines(value: boolean): void {
        this.includeCutOutlines = value;
    }
}

export {OutputBump}
