import {OutputType} from "./OutputType";
import {FormatType} from "./FormatType";
import {BaseOutput2DCff} from "./BaseOutput2DCff";
import type {IOutputRequestProd} from "./IOutputRequestProd";

class OutputCFF extends BaseOutput2DCff implements IOutputRequestProd{
    constructor();
    constructor(other: OutputCFF);
    constructor(drawingId?: number | null);
    constructor(...arr: any[]) {
        if(arr.length == 0){
            super(OutputType.CF2, FormatType.CF2, null);
        }
        else if (arr.length === 1) {
            if(arr[0] instanceof OutputCFF){
                const other: OutputCFF = arr[0];
                super(other);
            }
            else{
                super(OutputType.CF2, FormatType.CF2, arr[0]);
            }
        }
    }
}

export {OutputCFF}
