import {BaseOutputResponseDTO} from "./helper/BaseOutputResponseDTO";

class ResizeResponseDTO extends BaseOutputResponseDTO {
	private evdDestinationUrl!: string;

	public getEvdDestinationUrl(): string {
		return this.evdDestinationUrl;
	}

	public setEvdDestinationUrl(evdDestinationUrl: string): void {
		this.evdDestinationUrl = evdDestinationUrl;
	}
}

export {ResizeResponseDTO}
