import {BaseOutput3DPdf} from "./BaseOutput3DPdf";
import {OutputType} from "./OutputType";
import {FormatType} from "./FormatType";
import type {IOutputRequestPrev} from "./IOutputRequestPrev";

class OutputCollada extends BaseOutput3DPdf  implements IOutputRequestPrev {

    colladaCompressionLevel!: number;

    constructor();
    constructor(other: OutputCollada);
    constructor(drawingId?: number | null);
    constructor(...arr: any[]) {
        if(arr.length == 0){
            super(OutputType.COLLADA, FormatType.ZAE3D, null);
        }
        else if (arr.length === 1) {
            if(arr[0] instanceof OutputCollada){
                const other: OutputCollada = arr[0];
                super(other);
                this.colladaCompressionLevel = other.colladaCompressionLevel;
            }
            else{
                super(OutputType.COLLADA, FormatType.ZAE3D, arr[0]);
            }
        }
    }
}

export {OutputCollada}
