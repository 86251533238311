class Attachment {
    // private data: byte[]; TODO
    private name!: string;

    public getName(): string {
        return this.name;
    }

    public setName(name: string): void {
        this.name = name;
    }
}

export {Attachment}