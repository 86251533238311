import {Element, ElementType} from "./Element";
import type {IProcessing} from "../../../../../editor/editor/dto/ArtworkPackage";

export class ImageElement extends Element{

    public image: CanvasImageSource|HTMLImageElement|null;

    constructor(imageElement: ImageElement);
    constructor(image: CanvasImageSource|HTMLImageElement|null, x: number|null, y: number|null, width: number|null, height: number|null, processing: IProcessing|null);
    constructor(...arr: any[]) {
        if(arr.length === 1) {
            const element: ImageElement = arr[0] as ImageElement;
            super(element);
            this.image = element.image;
        }
        else {
            super(ElementType.IMAGE, arr[1], arr[2], arr[3], arr[4], arr[5]);
            this.image = arr[0];
        }
    }
}