enum ViewSide {
    /// <summary>
    /// Unknwon is specified by the CRM, in case the view side  is not specified by the user.<br/>
    /// when the CAD system recieves this value, it will use the default view-side direction set in the resizale design<br/>
    /// </summary>
    DVS_Unknown = "DVS_Unknown",
    /// <summary>
    /// Makes sure the design is views from the front(print) side.
    /// </summary>
    DVS_ViewFront = "DVS_ViewFront",
    /// <summary>
    /// Makes sure the design is views from the rear side.
    /// </summary>
    DVS_ViewBack = "DVS_ViewBack"
}

export {ViewSide}