import type {IDto} from "../../IDto";
import {MaterialDefDTO} from "../../cadoverride/utils/MaterialDefDTO";
import {FluteDir} from "./FluteDir";
import {Type} from "class-transformer";
import {DieboardInfo} from "./DieboardInfo";
import {DesignDrawing} from "../design/DesignDrawing";
import type {DesignParameter} from "../design/DesignParameter";
import {DesignParameterValue} from "../design/DesignParameterValue";
import {ModifyBleed} from "./ModifyBleed";

abstract class BaseDrawingValues implements IDto {

    private id!: number;
    @Type(() => MaterialDefDTO)
    private material: MaterialDefDTO|null = null;
    private flutedir: FluteDir = FluteDir.FD_unknown;
    @Type(() => DieboardInfo)
    private dieboard!: DieboardInfo;
    @Type(() => DesignParameterValue)
    private params: DesignParameterValue[] = [];
    @Type(() => ModifyBleed)
    private bleedInfo: ModifyBleed[] = [];

    constructor(d: BaseDrawingValues)
    constructor(drawingValues: DesignDrawing)
    constructor(...arr: any[]) {
        const self: BaseDrawingValues = this;
        if(arr[0] instanceof BaseDrawingValues){
            const d: BaseDrawingValues= arr[0] as BaseDrawingValues;
            this.material = d.material;
            this.flutedir = d.flutedir;
            this.dieboard = d.dieboard;
            this.bleedInfo = d.bleedInfo;
            this.id = d.id;
            if (d.getParams())
                d.getParams().forEach(function(param: DesignParameterValue) {
                    self.params.push(new DesignParameterValue(param));
                });
        }
        else if(arr[0] instanceof DesignDrawing){
            const drawingValues: DesignDrawing= arr[0] as DesignDrawing;
            this.material = drawingValues.getMaterial();
            this.flutedir = drawingValues.getFlutedir();
            this.dieboard = drawingValues.getDieboard();
            this.id = drawingValues.getId();
            if (drawingValues.getParams())
                drawingValues.getParams().forEach(function(param: DesignParameter) {
                    self.params.push(new DesignParameterValue(param));
                });
        }
    }

    public getParams(): DesignParameterValue[]{
        return this.params;
    }

    public getId(): number {
        return this.id;
    }

    public setId(id: number) {
        this.id = id;
    }

    public getMaterial(): MaterialDefDTO|null {
        return this.material;
    }

    public setMaterial(material: MaterialDefDTO|null): void {
        this.material = material;
    }

    public getFlutedir(): FluteDir {
        return this.flutedir;
    }

    public setFlutedir(flutedir: FluteDir): void {
        this.flutedir = flutedir;
    }

    public getDieboard(): DieboardInfo {
        return this.dieboard;
    }

    public setDieboard(dieboard: DieboardInfo): void {
        this.dieboard = dieboard;
    }

    public getBleedInfo(): ModifyBleed[] {
        return this.bleedInfo;
    }

    public setBleedInfo(value: ModifyBleed[]): void {
        this.bleedInfo = value;
    }
}

export {BaseDrawingValues}