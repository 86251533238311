import {Element, ElementType} from "./Element";
import type {IProcessing} from "../../../../../editor/editor/dto/ArtworkPackage";

export class TextElement extends Element{

    public text: string;

    constructor(imageElement: TextElement);
    constructor(text: string|null, x: number|null, y: number|null, width: number|null, height: number|null, processing: IProcessing|null);
    constructor(...arr: any[]) {
        if(arr.length === 1) {
            const element: TextElement = arr[0] as TextElement;
            super(element);
            this.text = element.text;
        }
        else {
            super(ElementType.TEXT, arr[1], arr[2], arr[3], arr[4], arr[5]);
            this.text = arr[0];
        }
    }
}