import type {LibResponse} from "./LibResponse";

export class LibRequest<T>{

    #url: string;
    #execute: (url: string)=>Promise<LibResponse<T>>;

    constructor(url: string, execute: (url: string)=>Promise<LibResponse<T>>) {
        this.#url = url;
        this.#execute = execute;
    }

    public getRequestUrl(): string {
        return this.#url;
    }

    public async doRequest(): Promise<LibResponse<T>> {
        return this.#execute(this.#url);
    }
}