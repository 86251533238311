import {Type} from "class-transformer";
import {ElementProcessingDTO} from "@engview/lib-icommon";
import {rect2d} from "../../../cad/utils/rect2d";

class OutputBumpData {

    private mapIDData!: string;
    @Type(() => ElementProcessingDTO)
    private process!: ElementProcessingDTO;
    @Type(() => rect2d)
    private position!: rect2d;

    public getMapIDData(): string {
        return this.mapIDData;
    }

    public setMapIDData(value: string): void {
        this.mapIDData = value;
    }

    public getProcess(): ElementProcessingDTO {
        return this.process;
    }

    public setProcess(value: ElementProcessingDTO): void {
        this.process = value;
    }

    public getPosition(): rect2d {
        return this.position;
    }

    public setPosition(value: rect2d): void {
        this.position = value;
    }
}

export {OutputBumpData}
