class TextFontInfo {
    private name!: string;
    private size!: number;
    private sizeSpecified!: boolean;
    private bold!: boolean;
    private boldSpecified!: boolean;
    private italic!: boolean;
    private italicSpecified!: boolean;
    private underlined!: boolean;
    private underlinedSpecified!: boolean;
    private strikeout!: boolean;
    private strikeoutSpecified!: boolean;
    private charset!: number;
    private charsetSpecified!: boolean;

    public getName(): string {
        return this.name;
    }

    public setName(name: string): void {
        this.name = name;
    }

    public getSize(): number {
        return this.size;
    }

    public setSize(size: number): void {
        this.size = size;
    }

    public isSizeSpecified(): boolean {
        return this.sizeSpecified;
    }

    public setSizeSpecified(sizeSpecified: boolean): void {
        this.sizeSpecified = sizeSpecified;
    }

    public isBold(): boolean {
        return this.bold;
    }

    public setBold(bold: boolean): void {
        this.bold = bold;
    }

    public isBoldSpecified(): boolean {
        return this.boldSpecified;
    }

    public setBoldSpecified(boldSpecified: boolean) {
        this.boldSpecified = boldSpecified;
    }

    public isItalic(): boolean {
        return this.italic;
    }

    public setItalic(italic: boolean): void {
        this.italic = italic;
    }

    public isItalicSpecified(): boolean {
        return this.italicSpecified;
    }

    public setItalicSpecified(italicSpecified: boolean): void {
        this.italicSpecified = italicSpecified;
    }

    public isUnderlined(): boolean {
        return this.underlined;
    }

    public setUnderlined(underlined: boolean): void {
        this.underlined = underlined;
    }

    public isUnderlinedSpecified(): boolean {
        return this.underlinedSpecified;
    }

    public setUnderlinedSpecified(underlinedSpecified: boolean): void {
        this.underlinedSpecified = underlinedSpecified;
    }

    public isStrikeout(): boolean {
        return this.strikeout;
    }

    public setStrikeout(strikeout: boolean): void {
        this.strikeout = strikeout;
    }

    public isStrikeoutSpecified(): boolean {
        return this.strikeoutSpecified;
    }

    public setStrikeoutSpecified(strikeoutSpecified: boolean): void {
        this.strikeoutSpecified = strikeoutSpecified;
    }

    public getCharset(): number {
        return this.charset;
    }

    public setCharset(charset: number): void {
        this.charset = charset;
    }

    public isCharsetSpecified(): boolean {
        return this.charsetSpecified;
    }

    public setCharsetSpecified(charsetSpecified: boolean): void {
        this.charsetSpecified = charsetSpecified;
    }
}

export {TextFontInfo}