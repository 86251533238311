import type {IDto} from "../IDto";
import type {IOutputRequestPrev} from "./outputs/impl/IOutputRequestPrev";

abstract class BaseOutputRequest<T extends IOutputRequestPrev> implements IDto {

    outputRequests: T[] = [];

    public addOutputRequest(cadOutput: T): void {
        this.outputRequests.push(cadOutput);
    }
}

export {BaseOutputRequest}