import type {IDto} from "../../IDto";
import {DesignParamterSelectionValue} from "./DesignParamterSelectionValue";
import {NLSString} from "../utils/NLSString";
import type {ParamCalcResult} from "../utils/ParamCalcResult";
import {Type} from "class-transformer";
import {DesignPlaceholderInfo} from "./DesignPlaceholderInfo";
import {DesignParameterValue} from "./DesignParameterValue";

class DesignParameter implements IDto {
    private remark!: string;
    @Type(() => DesignParamterSelectionValue)
    private valuelist!: DesignParamterSelectionValue[];
    private groups!: string[];
    @Type(() => NLSString)
    private description!: NLSString[];
    private value!: number;
    private valueBeforeRules!: number | null;
    private minExpression!: string;
    private maxExpression!: string;
    private name!: string;
    private expression!: string;
    private minValue!: number;
    private maxValue!: number;
    private calcResult!: ParamCalcResult | null;

    public getRemark(): string {
        return this.remark;
    }

    public setRemark(remark: string): void {
        this.remark = remark;
    }

    public getValuelist(): DesignParamterSelectionValue[] {
        return this.valuelist;
    }

    public setValuelist(valuelist: DesignParamterSelectionValue[]): void {
        this.valuelist = valuelist;
    }

    public getGroups(): string[] {
        return this.groups;
    }

    public setGroups(groups: string[]): void {
        this.groups = groups;
    }

    public getDescription(): NLSString[] {
        return this.description;
    }

    public setDescription(description: NLSString[]): void {
        this.description = description;
    }

    public getValue(): number {
        return this.value;
    }

    public setValue(value: number): void {
        this.value = value;
    }

    public getMinExpression(): string {
        return this.minExpression;
    }

    public setMinExpression(minExpression: string): void {
        this.minExpression = minExpression;
    }

    public getMaxExpression(): string {
        return this.maxExpression;
    }

    public setMaxExpression(maxExpression: string): void {
        this.maxExpression = maxExpression;
    }

    public getMinValue(): number {
        return this.minValue;
    }

    public setMinValue(minValue: number): void {
        this.minValue = minValue;
    }

    public getMaxValue(): number {
        return this.maxValue;
    }

    public setMaxValue(maxValue: number): void {
        this.maxValue = maxValue;
    }

    public getName() : string {
        return this.name;
    }

    public setName(name: string): void {
        this.name = name;
    }

    public getExpression(): string {
        return this.expression;
    }

    public setExpression(expression: string): void {
        this.expression = expression;
    }

    public static hasParam(params: DesignParameter[], paramsPerDrawing: DesignParameter[][], paramName: string): boolean {
        if (params!=null) {
            for(let i = 0; i < params.length; i++) {
                const param: DesignParameter = params[i];
                if(param.getName() === paramName) {
                    return true;
                }
            }
        }

        for(let i = 0; i < paramsPerDrawing.length; i++) {
            const pdParams: DesignParameter[] = paramsPerDrawing[i];
            if (pdParams !== null) {
                for (let j = 0; j < pdParams.length; j++) {
                    const param: DesignParameter = pdParams[j];
                    if(param.getName() === paramName) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    public getValueBeforeRules(): number | null {
        return this.valueBeforeRules;
    }

    public setValueBeforeRules(valueBeforeRules: number): void {
        this.valueBeforeRules = valueBeforeRules;
    }

    public getCalcResult(): ParamCalcResult | null {
        return this.calcResult;
    }

    public setCalcResult(calcResult: ParamCalcResult): void {
        this.calcResult = calcResult;
    }

    public clearCalcResult(): void {
        this.calcResult = null;
        this.valueBeforeRules = null;
    }
}

export {DesignParameter}