import {DieboardSizeMethod} from "./DieboardSizeMethod";
import {DieBoardChaseHolesInfo} from "./DieBoardChaseHolesInfo";
import {Type} from "class-transformer";

class DieboardInfo {
    private name!: string;
    private isUserDefined!: boolean;
    private width!: number;
    private height!: number;
    private marginL!: number;
    private marginR!: number;
    private marginT!: number;
    private marginB!: number;
    private widthSizing: DieboardSizeMethod = DieboardSizeMethod.FixedSize;
    private heightSizing: DieboardSizeMethod = DieboardSizeMethod.FixedSize;
    @Type(() => DieBoardChaseHolesInfo)
    private chaseholes: DieBoardChaseHolesInfo = new DieBoardChaseHolesInfo();

    constructor();
    constructor(orig: DieboardInfo);
    constructor(...arr: any[]) {
        if (arr.length == 1 && arr[0] instanceof DieboardInfo) {
            const orig: DieboardInfo = arr[0];
            this.name = orig.name;
            this.isUserDefined = orig.isUserDefined;
            this.width = orig.width;
            this.height = orig.height;
            this.marginL = orig.marginL;
            this.marginR = orig.marginR;
            this.marginT = orig.marginT;
            this.marginB = orig.marginB;
            this.widthSizing = orig.widthSizing;
            this.heightSizing = orig.heightSizing;
            this.chaseholes = new DieBoardChaseHolesInfo(orig.chaseholes);
        }
    }

    public getName(): string {
        return this.name;
    }
    public setName(name: string): void {
        this.name = name;
    }

    public getWidth(): number {
        return this.width;
    }
    public setWidth(width: number): void {
        this.width = width;
    }

    public getHeight(): number {
        return this.height;
    }

    public setHeight(height: number): void {
        this.height = height;
    }

    public getMarginL(): number {
        return this.marginL !== null ? this.marginL : 0;
    }

    public setMarginL(marginL: number): void {
        this.marginL = marginL;
    }

    public getMarginR(): number {
        return this.marginR !== null ? this.marginR : 0;
    }

    public setMarginR(marginR: number): void {
        this.marginR = marginR;
    }

    public getMarginT(): number {
        return this.marginT !== null ? this.marginT : 0;
    }

    public setMarginT(marginT: number) {
        this.marginT = marginT;
    }

    public getMarginB(): number {
        return this.marginB !== null ? this.marginB : 0;
    }

    public setMarginB(marginB: number): void {
        this.marginB = marginB;
    }

    public getWidthSizing(): DieboardSizeMethod {
        return this.widthSizing;
    }

    public setWidthSizing(widthSizing: DieboardSizeMethod): void {
        this.widthSizing = widthSizing;
    }

    public getHeightSizing(): DieboardSizeMethod {
        return this.heightSizing;
    }

    public setHeightSizing(heightSizing: DieboardSizeMethod): void {
        this.heightSizing = heightSizing;
    }

    public getChaseholes(): DieBoardChaseHolesInfo {
        return this.chaseholes;
    }

    public setChaseholes(chaseholes: DieBoardChaseHolesInfo): void {
        this.chaseholes = chaseholes;
    }

    public getIsUserDefined(): boolean {
        return this.isUserDefined;
    }
    public setIsUserDefined(isUserDefined: boolean): void {
        this.isUserDefined = isUserDefined;
    }
}

export {DieboardInfo}