import type {IDto} from "../../IDto";
import {Type} from "class-transformer";
import {ElementProcessingDTO} from "@engview/lib-icommon";

class DrawingArtworkSimple implements IDto {
    @Type(() => ElementProcessingDTO)
    private backing: ElementProcessingDTO | null;
    @Type(() => ElementProcessingDTO)
    private lamination: ElementProcessingDTO | null;

    constructor(lamination: ElementProcessingDTO | null, backing: ElementProcessingDTO | null) {
        this.lamination = lamination;
        this.backing = backing;
    }

    public getBacking(): ElementProcessingDTO | null {
        return this.backing;
    }

    public setBacking(backing: ElementProcessingDTO | null): void {
        this.backing = backing;
    }

    public getLamination(): ElementProcessingDTO | null {
        return this.lamination;
    }

    public setLamination(lamination: ElementProcessingDTO | null): void {
        this.lamination = lamination;
    }
}

export {DrawingArtworkSimple}