import {NLSString} from "../utils/NLSString";
import {Type} from "class-transformer";

class LayoutPatternTemplate {
    private drawingIds!: number[];
    private id!: string;
    @Type(() => NLSString)
    private description!: NLSString[];
    private isSystem!: boolean;

    public getDrawingIds(): number[] {
        return this.drawingIds;
    }

    public getId(): string {
        return this.id;
    }

    public getDescription(): NLSString[] {
        return this.description;
    }

    public getIsSystem(): boolean {
        return this.isSystem;
    }
}

export {LayoutPatternTemplate}