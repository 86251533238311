import {DieCuttingSide} from "../../cad/utils/DieCuttingSide";
import {LocalizedName} from "../../cad/utils/LocalizedName";
import {Type} from "class-transformer";
import {ElementProcessingDTO} from "@engview/lib-icommon";

class MaterialDefDTO {

    private name!: string;
    private types!: string[];
    private thickness!: number;
    private grammage!: number;
    @Type(() => LocalizedName)
    private description!: LocalizedName;
    private cuttingSide: DieCuttingSide = DieCuttingSide.DCS_Unknown;
    @Type(() => ElementProcessingDTO)
    private front!: ElementProcessingDTO;
    @Type(() => ElementProcessingDTO)
    private back!: ElementProcessingDTO;

    public getName(): string {
        return this.name;
    }

    public getTypes(): string[] {
        return this.types;
    }

    public getGrammage(): number {
        return this.grammage;
    }

    public getDescription(): LocalizedName {
        return this.description;
    }

    public getThickness(): number {
        return this.thickness;
    }

    public equals(o: any): boolean {
        return o instanceof MaterialDefDTO && this.name === o.getName();
    }

    public compareTo(o: MaterialDefDTO): number {
        if(o == null) {
            return 1;
        }
        let res = (this.thickness != null && o.thickness != null)
            ? (this.thickness !== o.thickness)
                ? this.thickness > o.thickness ? 1 : -1
                : 0
            : 0;
        if(res === 0) {
            return this.name.localeCompare(o.name);
        }
        return res;
    }

    public getCuttingSide(): DieCuttingSide {
        return this.cuttingSide;
    }

    public getFront(): ElementProcessingDTO {
        return this.front;
    }

    public getBack(): ElementProcessingDTO {
        return this.back;
    }
}

export {MaterialDefDTO}