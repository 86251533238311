import type {TextInputType} from "./TextInputType";

class TextInputConstraints {
    private lenMin!: number;
    private lenMax!: number;
    private filter!: TextInputType;

    public getLenMin(): number {
        return this.lenMin;
    }

    public setLenMin(lenMin: number): void {
        this.lenMin = lenMin;
    }

    public getLenMax(): number {
        return this.lenMax;
    }

    public setLenMax(lenMax: number) {
        this.lenMax = lenMax;
    }

    public getFilter(): TextInputType {
        return this.filter;
    }

    public setFilter(filter: TextInputType): void {
        this.filter = filter;
    }
}

export {TextInputConstraints}