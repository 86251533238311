import {MaterialDefDTO} from "../../cadoverride/utils/MaterialDefDTO";
import {Type} from "class-transformer";
import type {FluteDir} from "../utils/FluteDir";
import {DrawingValuesSimple} from "../utils/DrawingValuesSimple";
import {DesignParameterValue} from "./DesignParameterValue";
import type {DesignProject} from "./DesignProject";
import type {DesignDrawing} from "./DesignDrawing";
import type {DesignParameter} from "./DesignParameter";
import {BoxSizeMainparamsSimple} from "../utils/BoxSizeMainparamsSimple";

class DesignProjectSimple {

    private fluteDir: FluteDir;
    @Type(() => DrawingValuesSimple)
    private drawings: DrawingValuesSimple[] = [];
    @Type(() => DesignParameterValue)
    private params: DesignParameterValue[] = [];
    @Type(() => MaterialDefDTO)
    private material: MaterialDefDTO|null = null;
    @Type(() => BoxSizeMainparamsSimple)
    private boxSizeMainparams: BoxSizeMainparamsSimple | null = null;

    constructor(designProject: DesignProject) {
        const self: DesignProjectSimple = this;
        this.fluteDir = designProject.getBasicinfo().getFlutedir();
        this.material = designProject.getMaterial();
        if (designProject.getBasicinfo().getMainparams())
            this.boxSizeMainparams = new BoxSizeMainparamsSimple(designProject.getBasicinfo().getMainparams());
        designProject.getDrawings().forEach(function (drawing: DesignDrawing){
            self.drawings.push(new DrawingValuesSimple(drawing))
        });
        if (designProject.getParams())
            designProject.getParams().forEach(function(param: DesignParameter) {
                self.params.push(new DesignParameterValue(param));
            });
    }


    public getFluteDir(): FluteDir{
        return this.fluteDir;
    }

    public setFluteDir(value: FluteDir): void{
        this.fluteDir = value;
    }

    public getBoxSizeMainparams(): BoxSizeMainparamsSimple | null{
        return this.boxSizeMainparams;
    }

    public getMaterial(): MaterialDefDTO|null{
        return this.material;
    }

    public setMaterial(value: MaterialDefDTO|null): void{
        this.material = value;
    }

    public getParams(): DesignParameterValue[]{
        return this.params;
    }

    public getDrawings(): DrawingValuesSimple[]{
        return this.drawings;
    }
}

export {DesignProjectSimple}