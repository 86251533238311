import {Type} from "class-transformer";
import type {ProcessingInfo, ProcessingParamInfo} from "./ProcessingsPackage";

enum ShaderUniformType{
    scalar = "scalar",
    vec2 = "vec2",
    vec3 = "vec3",
    vec4 = "vec4",
    matrix2X2 = "matrix2X2",
    matrix3X3 = "matrix3X3",
    matrix4X4 = "matrix4X4",
    array = "array",
    arrayVec2 = "arrayVec2",
    arrayVec3 = "arrayVec3",
    arrayVec4 = "arrayVec4"
}

enum ColladaSamplerUniformType {
    ContentBump = "ContentBump",
    ContentRGBA = "ContentRGBA",
    ContentMultiChannel = "ContentMultiChannel",
    MaterialBump = "MaterialBump",
    MaterialRGBA = "MaterialRGBA"
}

enum SamplerChannelId {
    red = "red",
    green = "green",
    blue = "blue",
    alpha = "alpha",
    all = "all"
}

class ColladaFloatUniform {
    public values!: number[];
    public name!: string;
    public type!: ShaderUniformType;
}

class ColladaIntUniform {
    public values!: number[];
    public name!: string;
    public type!: ShaderUniformType;
}

abstract class ColladaUniformSet {
    @Type(() => ColladaFloatUniform)
    public floatParams!: ColladaFloatUniform[];
    @Type(() => ColladaIntUniform)
    public intParams!: ColladaIntUniform[];
}

class ProcessingParamValueDTO {
    public id!: string;
    public scalarValue: number|null = null;
    public colorValue: string|null = null;

    constructor(param: ProcessingParamInfo)
    constructor(...arr: any[]) {
        if(arr.length === 1) {
            const param: ProcessingParamInfo = arr[0] as ProcessingParamInfo;
            this.id = param.id;
            this.scalarValue = param.defValueScalar;
            this.colorValue = param.defValueColor;
        }
    }
}

class ElementProcessingDTO {
    @Type(() => ProcessingParamValueDTO)
    public params!: ProcessingParamValueDTO[];
    public id!: string;

    constructor(processingInfo: ProcessingInfo)
    constructor(...arr: any[]) {
        if(arr.length === 1) {
            const processingInfo: ProcessingInfo = arr[0] as ProcessingInfo;
            const self: ElementProcessingDTO = this;
            this.id = processingInfo.id;
            this.params = [];
            if(processingInfo.params)
                processingInfo.params.forEach(function (param: ProcessingParamInfo) {
                    self.params.push(new ProcessingParamValueDTO(param));
                });
        }
    }
}

class DrawingArtworkObjectIdDTO {
    public uid!: string;
    public IntId!: number;
}

class SamplerChannelDTO {
    @Type(() => ElementProcessingDTO)
    public processes!: ElementProcessingDTO[];
    @Type(() => DrawingArtworkObjectIdDTO)
    public objects!: DrawingArtworkObjectIdDTO[];
    public channel!: SamplerChannelId;
    public cutLines!: boolean;
    public frontContent!: boolean;
    public rearContent!: boolean;
}

class ColladaSamplerUniformDTO {
    @Type(() => SamplerChannelDTO)
    public channels!: SamplerChannelDTO[];
    public type!: ColladaSamplerUniformType;
    public name!: string;
    public contentId!: string;
    public sheetToContentUniformName!: string;
}

class ColladaSceneEnvironmentDTO extends ColladaUniformSet {
    @Type(() => ColladaSamplerUniformDTO)
    public samplers!: ColladaSamplerUniformDTO[];
}

class ExternalPartSceneInstance {
    public foldObjId!: string;
    public scolladaNodeName!: string;
}

class ExternalPartSceneObjectInfo {
    @Type(() => ExternalPartSceneInstance)
    public instances!: ExternalPartSceneInstance[];
    public Id!: string;
    public Name!: string;
}

class FoldPartSceneInstance {
    public panels!: string[];
    public measures!: string[];
    public foldObjId!: string;
}

class FoldPartSceneOnupInfo {
    @Type(() => FoldPartSceneInstance)
    public instances!: FoldPartSceneInstance[];
    public Id!: number;
}

class ColladaSceneContent {
    @Type(() => ExternalPartSceneObjectInfo)
    public externals!: ExternalPartSceneObjectInfo[];
    @Type(() => FoldPartSceneOnupInfo)
    public foldparts!: FoldPartSceneOnupInfo[];
}

class ColladaSamplerRef extends ColladaUniformSet {
    public name!: string;
    public channel!: SamplerChannelId;
}

class ColladaShaderLayer extends ColladaUniformSet {
    public name!: string;
    @Type(() => ColladaSamplerRef)
    public samplerrefs!: ColladaSamplerRef[];
}

class ColladaShaderDTO extends ColladaUniformSet {
    public drawingId!: number;
    public isFrontSide!: boolean;
    public name!: string;
    @Type(() => ColladaShaderLayer)
    public layers!: ColladaShaderLayer[];
    @Type(() => ColladaSamplerUniformDTO)
    public samplers!: ColladaSamplerUniformDTO[];
    public fragmentShaderCode!: string;
    public vertexShaderCode!: string;
}

class Animation {
    public endTime!: number;
    public initialPhaseIdx!: number;
    public initialStepIdx!: number;
    public initialTime!: number;
    @Type(() => Phase)
    public phases!: Phase[];
    public preFirstPhaseName!: String;
    public startTime!: number;
}

class Phase {
    public endTime!: number;
    public name!: string;
    @Type(() => Step)
    public steps!: Step[];
}

class Step {
    public endTime!: number;
    public externalAnimation!: boolean;
    public nSideActions!: number;
}

class ColladaScene {
    @Type(() => Animation)
    public animations!: Animation;
    @Type(() => ColladaSceneEnvironmentDTO)
    public environment!: ColladaSceneEnvironmentDTO;
    @Type(() => ColladaShaderDTO)
    public shaders!: ColladaShaderDTO[];
    @Type(() => ColladaSceneContent)
    public objects!: ColladaSceneContent;
}

export {ShaderUniformType,
        ColladaSamplerUniformType,
        SamplerChannelId,
        ColladaFloatUniform,
        ColladaIntUniform,
        SamplerChannelDTO,
        ProcessingParamValueDTO,
        ElementProcessingDTO,
        DrawingArtworkObjectIdDTO,
        ColladaSamplerUniformDTO,
        ColladaSceneEnvironmentDTO,
        ColladaSceneContent,
        ExternalPartSceneObjectInfo,
        ExternalPartSceneInstance,
        FoldPartSceneOnupInfo,
        FoldPartSceneInstance,
        ColladaShaderDTO,
        ColladaShaderLayer,
        ColladaSamplerRef,
        Animation,
        Phase,
        Step,
        ColladaScene}