class BoxSize {
    private l!: number;
    private w!: number;
    private h!: number;

    public getL(): number {
        return this.l ;
    }

    public setL(l: number): void {
        this.l = l;
    }

    public getW(): number {
        return this.w;
    }

    public setW(w: number): void {
        this.w = w;
    }

    public getH(): number {
        return this.h;
    }

    public setH(h: number): void {
        this.h = h;
    }
}

export {BoxSize}