import {BaseOutput3DPdf} from "./BaseOutput3DPdf";
import {OutputType} from "./OutputType";
import {FormatType} from "./FormatType";
import type {IOutputRequestPrev} from "./IOutputRequestPrev";

class OutputPDF3D extends BaseOutput3DPdf implements IOutputRequestPrev {
    constructor();
    constructor(other: OutputPDF3D);
    constructor(drawingId?: number | null);
    constructor(...arr: any[]) {
        if(arr.length == 0){
            super(OutputType.PDF3D, FormatType.PDF, null);
        }
        else if (arr.length === 1) {
            if(arr[0] instanceof OutputPDF3D){
                const other: OutputPDF3D = arr[0];
                super(other);
            }
            else{
                super(OutputType.PDF3D, FormatType.PDF, arr[0]);
            }
        }
    }
}

export {OutputPDF3D}
