import {OutputType} from "./OutputType";
import {FormatType} from "./FormatType";
import type {PreviewType} from "./PreviewType";
import {BaseOutput3DImage} from "./BaseOutput3DImage";
import type {IOutputRequestPrev} from "./IOutputRequestPrev";

class OutputPreview3D extends BaseOutput3DImage implements IOutputRequestPrev {

    public previewType!: PreviewType;

    constructor();
    constructor(other: OutputPreview3D);
    constructor(drawingId?: number | null);
    constructor(...arr: any[]) {
        if(arr.length == 0){
            super(OutputType.PREVIEW3D, FormatType.PNG, null);
        }
        else if (arr.length === 1) {
            if(arr[0] instanceof OutputPreview3D){
                const other: OutputPreview3D = arr[0];
                super(other);
                this.previewType = other.previewType;
            }
            else{
                super(OutputType.PREVIEW3D, FormatType.PNG, arr[0]);
            }
        }
    }
}

export {OutputPreview3D}
