import {BaseOutputDirectoryRequest} from "./BaseOutputDirectoryRequest";
import {FluteDir} from "../cad/utils/FluteDir";
import {MaterialDefDTO} from "../cadoverride/utils/MaterialDefDTO";
import {Type} from "class-transformer";
import {DesignParameterValue} from "../cad/design/DesignParameterValue";
import {DrawingValues} from "../cad/utils/DrawingValues";
import type {IOutputRequestProd} from "./outputs/impl/IOutputRequestProd";
import type {IOutputRequestPrev} from "./outputs/impl/IOutputRequestPrev";

class BaseResizeRequest<T extends IOutputRequestPrev, P extends IOutputRequestProd> extends BaseOutputDirectoryRequest<T, P> {

    private dropArtwork!: boolean;
    private fluteDir: FluteDir = FluteDir.FD_unknown;
    @Type(() => DrawingValues)
    private drawings!: DrawingValues[];
    @Type(() => DesignParameterValue)
    private params!: DesignParameterValue[];
    @Type(() => MaterialDefDTO)
    private material!: MaterialDefDTO|null;

    public isDropArtwork(): boolean {
        return this.dropArtwork;
    }

    public setDropArtwork(dropArtwork: boolean): void {
        this.dropArtwork = dropArtwork;
    }

    public getFluteDir(): FluteDir {
        return this.fluteDir;
    }
    public setFluteDir(fluteDir: FluteDir): void {
        this.fluteDir = fluteDir;
    }

    public getParams(): DesignParameterValue[] {
        return this.params;
    }

    public setParams(params: DesignParameterValue[]): void {
        this.params = params;
    }

    public getDrawings(): DrawingValues[] {
        return this.drawings;
    }

    public setDrawings(drawings: DrawingValues[]): void {
        this.drawings = drawings;
    }

    public getMaterial(): MaterialDefDTO|null {
        return this.material;
    }

    public setMaterial(material: MaterialDefDTO|null): void {
        this.material = material;
    }
}

export { BaseResizeRequest };