import {OutputRequest} from "./OutputRequest";
import type {OutputType} from "./OutputType";
import type {FormatType} from "./FormatType";

abstract class BaseOutput3D extends OutputRequest {

    public shotName!: string;

    constructor(other: BaseOutput3D);
    constructor(type: OutputType, format: FormatType, drawingId: number | null);
    constructor(...arr: any[]) {
        super(...arr);
        if(arr.length == 1){
            const other: BaseOutput3D = arr[0];
            this.shotName = other.shotName;
        }
    }
}

export {BaseOutput3D}
