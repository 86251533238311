import {MaterialDefDTO} from "../../cadoverride/utils/MaterialDefDTO";
import {DesignBasicInfo} from "./DesignBasicInfo";
import {DesignParameter} from "./DesignParameter";
import {DesignParameterGroup} from "./DesignParameterGroup";
import {DesignDrawing} from "./DesignDrawing";
import {LayoutPatternTemplate} from "../layout/LayoutPatternTemplate";
import {Type} from "class-transformer";

class DesignProject {
    private description!: string;
    @Type(() => DesignBasicInfo)
    private basicinfo!: DesignBasicInfo;
    @Type(() => MaterialDefDTO)
    private material!: MaterialDefDTO;
    @Type(() => DesignParameter)
    private params!: DesignParameter[];
    @Type(() => DesignParameterGroup)
    private groups!: DesignParameterGroup[];
    @Type(() => DesignDrawing)
    private drawings: DesignDrawing[] = [];
    @Type(() => LayoutPatternTemplate)
    private layoutPatterns!: LayoutPatternTemplate[];

    public getDrawings(): DesignDrawing[] {
        return this.drawings;
    }

    public addDrawings(drawing: DesignDrawing) {
        this.drawings.push(drawing);
    }

    public getParams(): DesignParameter[] {
        return this.params;
    }

    public setParams(params: DesignParameter[]): void {
        this.params = params;
    }

    public getBasicinfo(): DesignBasicInfo {
        return this.basicinfo;
    }

    public setBasicinfo(basicinfo: DesignBasicInfo): void {
        this.basicinfo = basicinfo;
    }

    public getLayoutPatterns(): LayoutPatternTemplate[] {
        return this.layoutPatterns;
    }

    public getGroups(): DesignParameterGroup[] {
        return this.groups;
    }

    public getMaterial(): MaterialDefDTO {
        return this.material;
    }

    public getDescription(): string {
        return this.description;
    }

    public clearParamCalcResults(): void {
        if (this.params !== null) {
            this.params.forEach(function (p){
                p.clearCalcResult();
            });
        }
    }
}

export {DesignProject}