import {NLSString} from "../utils/NLSString";
import {Type} from "class-transformer";

class DesignParamterSelectionValue {
    private name!: string;
    @Type(() => NLSString)
    private description!: NLSString[];
    private value!: number;

    public getName(): string {
        return this.name;
    }

    public setName(name: string): void {
        this.name = name;
    }

    public getDescription(): NLSString[] {
        return this.description;
    }

    public setDescription(description: NLSString[]): void {
        this.description = description;
    }

    public getValue(): number {
        return this.value;
    }

    public setValue(value: number) {
        this.value = value;
    }
}

export {DesignParamterSelectionValue}