import {DrawingArtwork} from "./DrawingArtwork";
import {Type} from "class-transformer";
import {BaseDrawingValues} from "./BaseDrawingValues";
import type {DrawingValuesSimple} from "./DrawingValuesSimple";

class DrawingValues extends BaseDrawingValues {

    @Type(() => DrawingArtwork)
    private frontArtwork!: DrawingArtwork;
    @Type(() => DrawingArtwork)
    private rearArtwork!: DrawingArtwork;

    constructor(drawing: DrawingValuesSimple) {
        super(drawing);
        if(drawing.getFrontArtwork()){
            if(!this.frontArtwork)
                this.frontArtwork = new DrawingArtwork(drawing.getFrontArtwork());
        }
        if(drawing.getRearArtwork()){
            if(!this.rearArtwork)
                this.rearArtwork = new DrawingArtwork(drawing.getRearArtwork());
        }
    }

    public getFrontArtwork(): DrawingArtwork {
        return this.frontArtwork;
    }

    public setFrontArtwork(frontArtwork: DrawingArtwork): void {
        this.frontArtwork = frontArtwork;
    }

    public getRearArtwork(): DrawingArtwork {
        return this.rearArtwork;
    }

    public setRearArtwork(rearArtwork: DrawingArtwork): void {
        this.rearArtwork = rearArtwork;
    }
}

export {DrawingValues}