import {BaseOutput3D} from "./BaseOutput3D";
import type {OutputCollada} from "./OutputCollada";
import {OutputType} from "./OutputType";
import {FormatType} from "./FormatType";
import type {MetaData} from "@engview/lib-icommon";
import type {OutputShaders} from "./OutputShaders";
import type {IOutputRequestPrev} from "./IOutputRequestPrev";

class OutputViewerInit extends BaseOutput3D implements IOutputRequestPrev {

    private outputCollada!: OutputCollada|null;
    private outputShaders!: OutputShaders|null;
    private metaData!: MetaData|null;

    constructor(type: OutputType, outputCollada: OutputCollada|null, outputShaders: OutputShaders|null, metaData: MetaData|null);
    constructor(outputCollada: OutputCollada|null, outputShaders: OutputShaders|null, metaData: MetaData|null);
    constructor(other: OutputViewerInit);
    constructor(...arr: any[]) {
        if(arr.length == 4){
            const ot: OutputType = arr[0] as OutputType;
            const co: OutputCollada|null = arr[1] as OutputCollada;
            const so: OutputShaders|null = arr[2] as OutputShaders;
            const md: MetaData = arr[3] as MetaData;
            super(ot, FormatType.VIEWER, co != null ? co.drawingId : null);
            this.outputCollada = co;
            this.outputShaders = so;
            this.metaData = md;
        }
        else if(arr.length == 3){
            const co: OutputCollada|null = arr[0] as OutputCollada;
            const so: OutputShaders|null = arr[1] as OutputShaders;
            const md: MetaData = arr[2] as MetaData;
            super(OutputType.VIEWER_INIT, FormatType.VIEWER, co != null ? co.drawingId : null);
            this.outputCollada = co;
            this.outputShaders = so;
            this.metaData = md;
        }
        else if(arr.length == 1) {
            super(arr[0])
            const ovi: OutputViewerInit = arr[0] as OutputViewerInit;
            this.outputCollada = ovi.outputCollada;
            this.outputShaders = ovi.outputShaders;
            this.metaData = ovi.metaData;
        }
    }

    public setMetaData(metaData: MetaData|null): void{
        this.metaData = metaData;
    }

    public setOutputCollada(outputCollada: OutputCollada|null): void{
        this.outputCollada = outputCollada;
    }

    public setOutputShaders(outputShaders: OutputShaders|null): void{
        this.outputShaders = outputShaders;
    }
}

export {OutputViewerInit}