import {DrawingArtworkLayer} from "./DrawingArtworkLayer";
import {DrawingArtworkContent} from "./DrawingArtworkContent";
import {Type} from "class-transformer";
import {DrawingArtworkSimple} from "./DrawingArtworkSimple";
import {DrawingValuesSimple} from "./DrawingValuesSimple";

class DrawingArtwork extends DrawingArtworkSimple {
    @Type(() => DrawingArtworkLayer)
    private layers!: DrawingArtworkLayer[];
    @Type(() => DrawingArtworkContent)
    private contents!: DrawingArtworkContent[];

    constructor(drawingArtwork: DrawingArtworkSimple) {
        super(drawingArtwork.getLamination(), drawingArtwork.getBacking());
    }

    public getLayers(): DrawingArtworkLayer[] {
        return this.layers;
    }

    public setLayers(layers: DrawingArtworkLayer[]): void {
        this.layers = layers;
    }

    public getContents(): DrawingArtworkContent[] {
        return this.contents;
    }

    public setContents(contents: DrawingArtworkContent[]) {
        this.contents = contents;
    }
}

export {DrawingArtwork}