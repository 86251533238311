import {BaseData} from "./BaseData";

class SVGData {
    public url!: string;
}

class Viewer2DData extends BaseData {
    public svgs!: SVGData[];
}

export {Viewer2DData, SVGData}