enum TransformationType {
    ROTATE,
    SCALE,
    TRANSLATE
}
abstract class Transformation {
    public type: TransformationType;

    constructor(type: TransformationType) {
        this.type = type;
    }
}

abstract class TransformationXY extends Transformation {
    public x: number;
    public y: number;

    constructor(type: TransformationType, x: number, y: number) {
        super(type);
        this.x = x;
        this.y = y;
    }
}

class TransformRotate extends Transformation {
    public degrees: number;

    constructor(degrees: number) {
        super(TransformationType.ROTATE);
        this.degrees = degrees;
    }
}

class TransformScale extends TransformationXY{

    constructor(x: number, y: number) {
        super(TransformationType.SCALE, x, y);
    }
}

class TransformTranslate extends TransformationXY {

    constructor(x: number, y: number) {
        super(TransformationType.TRANSLATE, x, y);
    }
}

export {TransformationType, Transformation, TransformationXY, TransformRotate, TransformScale, TransformTranslate}