import {OutputType} from "./OutputType";
import {FormatType} from "./FormatType";
import {BaseOutput2DPdf} from "./BaseOutput2DPdf";
import type {IOutputRequestProd} from "./IOutputRequestProd";

class OutputPDF2D extends BaseOutput2DPdf  implements IOutputRequestProd {
    constructor();
    constructor(other: OutputPDF2D);
    constructor(drawingId?: number | null);
    constructor(...arr: any[]) {
        if(arr.length == 0){
            super(OutputType.PDF2D, FormatType.PDF, null);
        }
        else if (arr.length === 1) {
            if(arr[0] instanceof OutputPDF2D){
                const other: OutputPDF2D = arr[0];
                super(other);
            }
            else{
                super(OutputType.PDF2D, FormatType.PDF, arr[0]);
            }
        }
    }
}

export {OutputPDF2D}
