import type {IProxyViewer3D} from "./viewer/IProxyViewer3D";
import type {IProxyViewer2D} from "./viewer/IProxyViewer2D";

export abstract class IViewer3D {

    public static Viewer3DEvent: string = "Sys_Viewer_Loaded";
    public static getViewer(): Promise<IViewer3D> {
        return new Promise<IViewer3D>(function (resolve: Function, reject: Function){

            let subs: any;

            const done: any = function(): boolean{
                if((window as any).EngView && (window as any).EngView.Viewer3D) {
                    resolve((window as any).EngView.Viewer3D);
                    window.removeEventListener(IViewer3D.Viewer3DEvent, subs);
                    return true;
                }
                return false;
            }

            if(!done())
                subs = window.addEventListener(IViewer3D.Viewer3DEvent, done);
        });
    }

    abstract get proxy(): IProxyViewer3D;
    abstract get proxy2d(): IProxyViewer2D;
    abstract get hasProxy(): boolean;
    abstract get hasProxy2d(): boolean;
    abstract load(container: HTMLElement | null, container2D: HTMLDivElement|null): Promise<void>;
}