class StrippingRulesInfo {
    private numLeftKnives!: number;
    private numRightKnives!: number;
    private numTopKnives!: number;
    private symmetricalHKnives!: boolean;
    private knivesStyle!: string;
    private hKniveExtension!: number;
    private vKniveExtension!: number;
    private hKniveNotch!: number;
    private vKniveNotch!: number;

    public getNumLeftKnives(): number {
        return this.numLeftKnives;
    }

    public setNumLeftKnives(numLeftKnives: number): void {
        this.numLeftKnives = numLeftKnives;
    }

    public getNumRightKnives(): number {
        return this.numRightKnives;
    }

    public setNumRightKnives(numRightKnives: number): void {
        this.numRightKnives = numRightKnives;
    }

    public getNumTopKnives(): number {
        return this.numTopKnives;
    }

    public setNumTopKnives(numTopKnives: number): void {
        this.numTopKnives = numTopKnives;
    }

    public isSymmetricalHKnives(): boolean {
        return this.symmetricalHKnives;
    }

    public setSymmetricalHKnives(symmetricalHKnives: boolean): void {
        this.symmetricalHKnives = symmetricalHKnives;
    }

    public getKnivesStyle(): string {
        return this.knivesStyle;
    }

    public setKnivesStyle(knivesStyle: string): void {
        this.knivesStyle = knivesStyle;
    }

    public gethKniveExtension(): number {
        return this.hKniveExtension;
    }

    public sethKniveExtension(hKniveExtension: number): void {
        this.hKniveExtension = hKniveExtension;
    }

    public getvKniveExtension(): number {
        return this.vKniveExtension;
    }

    public setvKniveExtension(vKniveExtension: number): void {
        this.vKniveExtension = vKniveExtension;
    }

    public gethKniveNotch(): number {
        return this.hKniveNotch;
    }

    public sethKniveNotch(hKniveNotch: number): void {
        this.hKniveNotch = hKniveNotch;
    }

    public getvKniveNotch(): number {
        return this.vKniveNotch;
    }

    public setvKniveNotch(vKniveNotch: number): void {
        this.vKniveNotch = vKniveNotch;
    }
}

export {StrippingRulesInfo}