import {Type} from "class-transformer";
import {BaseDrawingValues} from "./BaseDrawingValues";
import {DrawingArtworkSimple} from "./DrawingArtworkSimple";
import type {DesignDrawing} from "../design/DesignDrawing";

class DrawingValuesSimple extends BaseDrawingValues {

    @Type(() => DrawingArtworkSimple)
    private frontArtwork!: DrawingArtworkSimple;
    @Type(() => DrawingArtworkSimple)
    private rearArtwork!: DrawingArtworkSimple;

    constructor(drawingValues: DesignDrawing) {
        super(drawingValues);
        this.frontArtwork = new DrawingArtworkSimple(drawingValues.getFrontLamination(), drawingValues.getFrontBacking());
        this.rearArtwork = new DrawingArtworkSimple(drawingValues.getRearLamination(), drawingValues.getRearBacking());
    }


    public getFrontArtwork(): DrawingArtworkSimple {
        return this.frontArtwork;
    }

    public setFrontArtwork(frontArtwork: DrawingArtworkSimple): void {
        this.frontArtwork = frontArtwork;
    }

    public getRearArtwork(): DrawingArtworkSimple {
        return this.rearArtwork;
    }

    public setRearArtwork(rearArtwork: DrawingArtworkSimple): void {
        this.rearArtwork = rearArtwork;
    }
}

export {DrawingValuesSimple}