import type {IFactory} from "@engview/lib-utils";
import type {ClassConstructor} from "class-transformer";
import type {ColladaScene} from "@engview/lib-icommon";

class OutputResponseFactory implements IFactory<OutputResponse> {
	public getClassFunction(o: any): ClassConstructor<OutputResponse> {
		switch (o.type) {
			case OutputResponseType.COLLADA:
				return ColladaOutputResponse;
			case OutputResponseType.NORMAL:
				return NormalOutputResponse;
			case OutputResponseType.BUMP:
				return BumpOutputResponse;
			default:
				throw new Error('Invalid layout type');
		}
	}
}

enum OutputResponseStatus {
	StatusOk = "StatusOk",
	StatusWarning = "StatusWarning",
	StatusFailedCantGenerate = "StatusFailedCantGenerate",
	StatusFailedCantUpload = "StatusFailedCantUpload",
	StatusFailedGenerationImpossible = "StatusFailedGenerationImpossible"
}

enum OutputResponseType {
	COLLADA="COLLADA",
	NORMAL="NORMAL",
	BUMP="BUMP"
}

abstract class OutputResponse {
	public type!: OutputResponseType;
	public status!: OutputResponseStatus;
	public statusText!: string;
	public layoutMapID!:string;
	public sheetMapID!: string;
	public mapID!: string;
	public resourceUuid!: string;
	public srcDrawingId!: number;

	constructor(type: OutputResponseType) {
		this.type = type;
	}
}

class NormalOutputResponse extends OutputResponse {
	constructor() {
		super(OutputResponseType.NORMAL)
	}
}

class ColladaOutputResponse extends OutputResponse{

	public colladaScene!: ColladaScene;

	constructor() {
		super(OutputResponseType.COLLADA)
	}
}

class BumpOutputResponse extends OutputResponse{

	public isFront!: boolean;

	constructor() {
		super(OutputResponseType.BUMP)
	}
}

export {OutputResponse, NormalOutputResponse, ColladaOutputResponse, OutputResponseStatus, OutputResponseType, BumpOutputResponse, OutputResponseFactory}