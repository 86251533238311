enum DrawingSideExt {
    DSE_Unknown = "DSE_Unknown",
    DSE_Current = "DSE_Current",
    DSE_CounterCurrent = "DSE_CounterCurrent",
    DSE_DesignFront = "DSE_DesignFront",
    DSE_DesignBack = "DSE_DesignBack",
    DSE_DieSide = "DSE_DieSide",
    DSE_CounterDieSide = "DSE_CounterDieSide"
}

export {DrawingSideExt}