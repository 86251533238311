import type {IProxyEditor3D} from "../editor/editor/IProxyEditor3D";

export abstract class IEditor3D {

    public static Editor3DEvent: string = "Sys_Editor_Loaded";
    public static getEditor(): Promise<IEditor3D> {
        return new Promise<IEditor3D>(function (resolve: Function, reject: Function){

            let subs: any;

            const done: any = function(): boolean{
                if((window as any).EngView && (window as any).EngView.Editor3D) {
                    resolve((window as any).EngView.Editor3D);
                    window.removeEventListener(IEditor3D.Editor3DEvent, subs);
                    return true;
                }
                return false;
            }

            if(!done())
                subs = window.addEventListener(IEditor3D.Editor3DEvent, done);
        });
    }

    abstract get proxy(): IProxyEditor3D;
    abstract load(container: HTMLElement|null, resourceUrl: string, resizeUrl: string, container2D: HTMLDivElement|null): Promise<void>;
}