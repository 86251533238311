import {Type} from "class-transformer";
import {ProcessingParamValueDTO} from "@engview/lib-icommon";

class DrawingArtworkLayer {
    private uid!: string;
    @Type(() => ProcessingParamValueDTO)
    private params!: ProcessingParamValueDTO[];
    private id!: string;

    public setUid(uid: string) {
        this.uid = uid;
    }

    public setParams(params: ProcessingParamValueDTO[]) {
        this.params = params;
    }

    public setId(id: string) {
        this.id = id;
    }
}

export {DrawingArtworkLayer}