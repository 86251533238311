class DesignMeasureLine {
    private remark!: string;

    /// The set of groups id this parameter belongs to. the 'groups' property of the DesignParametersArray object gives you names of the groups in all available languages
    private dependson!: string[];

    /// Name of the parameter. The name is generated to EngViewCAD, and is interpreted by it.
    private id!: string;

    private expression!: string;

    private viewpattern!: string;

    private value!: number;

    public getRemark(): string {
        return this.remark;
    }

    public setRemark(remark: string): void {
        this.remark = remark;
    }

    public getDependson(): string[] {
        return this.dependson;
    }

    public setDependson(dependson: string[]): void {
        this.dependson = dependson;
    }

    public getId(): string {
        return this.id;
    }

    public setId(id: string): void {
        this.id = id;
    }

    public getExpression(): string {
        return this.expression;
    }

    public setExpression(expression: string): void {
        this.expression = expression;
    }

    public getViewpattern(): string {
        return this.viewpattern;
    }

    public setViewpattern(viewpattern: string): void {
        this.viewpattern = viewpattern;
    }

    public getValue(): number {
        return this.value;
    }

    public setValue(value: number): void {
        this.value = value;
    }

    public dependsOnlyOn(hiddenParams: string[]) {//HashSet<String> hiddenParams
        if (this.dependson === null || this.dependson.length !== 1) {
            return false;
        }

        for(let i = 0; i < hiddenParams.length; i++) {
            const hp: string = hiddenParams[i];
            if (hp.endsWith("." + this.dependson[0])) {
                return true;
            }
        }
        return false;
    }
}

export {DesignMeasureLine}