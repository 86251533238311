import {OutputType} from "./OutputType";
import {FormatType} from "./FormatType";
import {BaseOutput3D} from "./BaseOutput3D";
import type {IOutputRequestPrev} from "./IOutputRequestPrev";

class OutputEVD extends BaseOutput3D  implements IOutputRequestPrev {
    constructor();
    constructor(other: OutputEVD);
    constructor(drawingId?: number | null);
    constructor(...arr: any[]) {
        if(arr.length == 0){
            super(OutputType.EVD, FormatType.EVD, null);
        }
        else if (arr.length === 1) {
            if(arr[0] instanceof OutputEVD){
                const other: OutputEVD = arr[0];
                super(other);
            }
            else{
                super(OutputType.EVD, FormatType.EVD, arr[0]);
            }
        }
    }
}

export {OutputEVD}
