import type {OutputCollada} from "./OutputCollada"
import type {MetaData} from "@engview/lib-icommon";
import {OutputType} from "./OutputType";
import {OutputViewerInit} from "./OutputViewerInit";
import type {OutputShaders} from "./OutputShaders";

class OutputEditorInit extends OutputViewerInit {
    constructor(outputCollada: OutputCollada|null, outputShaders: OutputShaders|null, metaData: MetaData|null){
        super(OutputType.EDITOR_INIT, outputCollada, outputShaders, metaData);
    }
}

export {OutputEditorInit}