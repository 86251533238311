import {DesignParameter} from "../design/DesignParameter";
import {Type} from "class-transformer";

class BoxSizeMainparams {
    @Type(() => DesignParameter)
    private L!: DesignParameter;
    @Type(() => DesignParameter)
    private W!: DesignParameter;
    @Type(() => DesignParameter)
    private H!: DesignParameter;
    @Type(() => DesignParameter)
    private DT!: DesignParameter;

    public getL(): DesignParameter {
        return this.L;
    }

    public setL(l: DesignParameter): void {
        this.L = l;
    }

    public getW(): DesignParameter {
        return this.W;
    }

    public setW(w: DesignParameter): void {
        this.W = w;
    }

    public getH(): DesignParameter {
        return this.H;
    }

    public setH(h: DesignParameter): void {
        this.H = h;
    }

    public getDT(): DesignParameter {
        return this.DT;
    }

    public setDT(dT: DesignParameter): void {
        this.DT = dT;
    }

    public asArray(): DesignParameter[] {
        const mp: DesignParameter[] = [];
        const l: DesignParameter|null = this.getL();
        if(l)
            mp.push(l);
        const w: DesignParameter|null = this.getW();
        if(w)
            mp.push(w);
        const h: DesignParameter|null = this.getH();
        if(h)
            mp.push(h);
        const dt: DesignParameter|null = this.getDT();
        if(dt)
            mp.push(dt);
        return mp;
    }

    public hasParam(paramName: string): boolean {
        if (this.L && this.L.getName() === paramName)
            return true;
        if (this.W && this.W.getName() === paramName)
            return true;
        if (this.H && this.H.getName() === paramName)
            return true;
        if (this.DT && this.DT.getName() === paramName)
            return true;
        return false;
    }

    public clearParamCalcResults(): void {
        if (this.DT !== null) {
            this.DT.clearCalcResult();
        }
        if (this.W !== null) {
            this.W.clearCalcResult();
        }
        if (this.H !== null) {
            this.H.clearCalcResult();
        }
        if (this.L !== null) {
            this.L.clearCalcResult();
        }
    }
}

export {BoxSizeMainparams}