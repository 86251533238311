import {NLSString} from "../utils/NLSString";
import {Type} from "class-transformer";

class DesignParameterGroup {
    private id!: string;
    @Type(() => NLSString)
    private name!: NLSString[];

    public getId(): string {
        return this.id;
    }
    public setId(id: string): void {
        this.id = id;
    }
    public getName(): NLSString[] {
        return this.name;
    }
    public setName(name: NLSString[]): void {
        this.name = name;
    }
}

export {DesignParameterGroup}