class CompensatingRulesInfo {

    private numRules!: number;

    private ruleSpacing!: number;

    private ruleLength!: number;

    private topMargin!: number;

    private bottomMargin!: number;

    private splitPiceLength!: number;

    private splitGap!: number;

    public getNumRules(): number {
        return this.numRules;
    }

    public setNumRules(numRules: number): void {
        this.numRules = numRules;
    }

    public getRuleSpacing(): number {
        return this.ruleSpacing;
    }

    public setRuleSpacing(ruleSpacing: number): void {
        this.ruleSpacing = ruleSpacing;
    }

    public getRuleLength(): number {
        return this.ruleLength;
    }

    public setRuleLength(ruleLength: number): void {
        this.ruleLength = ruleLength;
    }

    public getTopMargin(): number {
        return this.topMargin;
    }

    public setTopMargin(topMargin: number): void {
        this.topMargin = topMargin;
    }

    public getBottomMargin(): number {
        return this.bottomMargin;
    }

    public setBottomMargin(bottomMargin: number): void {
        this.bottomMargin = bottomMargin;
    }

    public getSplitPiceLength(): number {
        return this.splitPiceLength;
    }
    public setSplitPiceLength(splitPiceLength: number): void {
        this.splitPiceLength = splitPiceLength;
    }

    public getSplitGap(): number {
        return this.splitGap;
    }
    public setSplitGap(splitGap: number): void {
        this.splitGap = splitGap;
    }
}

export {CompensatingRulesInfo}