import {InlinedImage} from "../utils/InlinedImage";
import {TextFontInfo} from "../utils/TextFontInfo";
import {rotrect2d} from "../utils/rotrect2d";
import type {PlaceholderAlignment} from "../utils/PlaceholderAlignment";
import type {PlaceholderFitting} from "../utils/PlaceholderFitting";
import {Type} from "class-transformer";
import {ElementProcessingDTO} from "@engview/lib-icommon";

class DesignPlaceholderValue {
    private expression!: string;
    @Type(() => InlinedImage)
    private data!: InlinedImage;
    @Type(() => ElementProcessingDTO)
    private processes!: ElementProcessingDTO[];
    @Type(() => TextFontInfo)
    private font!: TextFontInfo;
    @Type(() => rotrect2d)
    private designPos!: rotrect2d;
    private id!: string;
    private allignment!: PlaceholderAlignment;
    private fitting!: PlaceholderFitting;
    private frontSide!: boolean;
    private color!: string;
    private bgcolor!: string;
    private requestimgcontents!: boolean;

    public getExpression(): string {
        return this.expression;
    }

    public setExpression(expression: string): void {
        this.expression = expression;
    }

    public getData(): InlinedImage {
        return this.data;
    }

    public setData(data: InlinedImage): void {
        this.data = data;
    }

    public getProcesses(): ElementProcessingDTO[] {
        return this.processes;
    }

    public setProcesses(processes: ElementProcessingDTO[]) {
        this.processes = processes;
    }

    public getFont(): TextFontInfo {
        return this.font;
    }

    public setFont(font: TextFontInfo) {
        this.font = font;
    }

    public getDesignPos(): rotrect2d {
        return this.designPos;
    }

    public setDesignPos(designPos: rotrect2d): void {
        this.designPos = designPos;
    }

    public getId(): string {
        return this.id;
    }

    public  setId(id: string): void {
        this.id = id;
    }

    public getAllignment(): PlaceholderAlignment {
        return this.allignment;
    }

    public setAllignment(allignment: PlaceholderAlignment) {
        this.allignment = allignment;
    }

    public getFitting(): PlaceholderFitting {
        return this.fitting;
    }

    public setFitting(fitting: PlaceholderFitting) {
        this.fitting = fitting;
    }

    public isFrontSide(): boolean {
        return this.frontSide;
    }

    public setFrontSide(frontSide: boolean): void {
        this.frontSide = frontSide;
    }

    public getColor(): string {
        return this.color;
    }

    public setColor(color: string): void {
        this.color = color;
    }

    public getBgcolor(): string {
        return this.bgcolor;
    }

    public setBgcolor(bgcolor: string): void {
        this.bgcolor = bgcolor;
    }

    public isRequestimgcontents(): boolean {
        return this.requestimgcontents;
    }

    public setRequestimgcontents(requestimgcontents: boolean) {
        this.requestimgcontents = requestimgcontents;
    }
}

export {DesignPlaceholderValue}