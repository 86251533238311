import {BaseOutput2D} from "./BaseOutput2D";
import type {ColorType} from "../types/ColorType";
import type {YesNo} from "../types/YesNo";
import type {ExportType} from "../types/ExportType";

export abstract class BaseOutput2DPdf extends BaseOutput2D {
    public caleWidthsInParts!: YesNo;
    public colorPolicy!: ColorType;

    public marginL!: number;
    public marginR!: number;
    public marginT!: number;
    public marginB!: number;

    public decomposePerfo!: YesNo;
    public decomposeComposites!: YesNo;
    public cutCreaseProcessing!: ExportType;

    public addArtworkToMedia!: YesNo;
    public skipImages!: YesNo;
    public skipIHatches!: YesNo;
    public skipFills!: YesNo;
    public exportStucturalOnly!: YesNo;
}
