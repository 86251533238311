import {BaseOutput3DPdf} from "./BaseOutput3DPdf";
import {OutputType} from "./OutputType";
import {FormatType} from "./FormatType";
import type {IOutputRequestPrev} from "./IOutputRequestPrev";

class OutputShaders extends BaseOutput3DPdf implements IOutputRequestPrev {

    constructor();
    constructor(other: OutputShaders);
    constructor(drawingId?: number | null);
    constructor(...arr: any[]) {
        if(arr.length == 0){
            super(OutputType.SHADERS, FormatType.SHADERUPDATE3D, null);
        }
        else if (arr.length === 1) {
            if(arr[0] instanceof OutputShaders){
                const other: OutputShaders = arr[0];
                super(other);
            }
            else{
                super(OutputType.SHADERS, FormatType.SHADERUPDATE3D, arr[0]);
            }
        }
    }
}

export {OutputShaders}
