import {BaseOutputRequest} from "./BaseOutputRequest";
import type {IOutputRequestProd} from "./outputs/impl/IOutputRequestProd";
import type {IOutputRequestPrev} from "./outputs/impl/IOutputRequestPrev";

abstract class BaseOutputRequestProd<T extends IOutputRequestPrev, P extends IOutputRequestProd> extends BaseOutputRequest<T> {

    outputRequestsProd: P[] = [];

    public addOutputRequestProd(cadOutput: P): void {
        this.outputRequestsProd.push(cadOutput);
    }
}

export {BaseOutputRequestProd}