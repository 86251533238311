import type {IDto} from "../../IDto";
import {Result} from "../utils/Result";
import {DesignProject} from "../design/DesignProject";
import {Type} from "class-transformer";

abstract class BaseResponse implements IDto {
    @Type(() => Result)
    private result!: Result;
    @Type(() => DesignProject)
    private design!: DesignProject;

    public getResult(): Result {
        return this.result;
    }

    public setResult(result: Result): void {
        this.result = result;
    }

    public getDesign(): DesignProject {
        return this.design;
    }

    public setDesign(design: DesignProject): void {
        this.design = design;
    }
}

export {BaseResponse}