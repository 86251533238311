import {ContentType} from "@engview/lib-utils";

enum FormatType {
    CF2 = "CF2",
    ZAE3D = "ZAE3D",
    VIEWER = "VIEWER",
    DXF = "DXF",
    EVD = "EVD",
    PDF = "PDF",
    JPG = "JPG",
    PNG = "PNG",
    SHADERUPDATE3D = "SHADERUPDATE3D",
    SVG = "SVG",
    DDE = "DDE",
    DD3 = "DD3",
    DWG = "DWG",
    AI = "AI",
    USDZ = "USDZ"
}

interface FormatTypeInfo {
    format: string | null;
    extension: string | null;
    mimeType?: string | null;
}

const FormatTypeInfoMap: Record<FormatType, FormatTypeInfo> = {
    [FormatType.CF2]: {
        format: "CF2",
        extension: "cff"
    },
    [FormatType.ZAE3D]: {
        format: "3DZAE",
        extension: "zae",
        mimeType: ContentType.ZAE
    },
    [FormatType.DXF]: {
        format: "DXF",
        extension: "dxf"
    },
    [FormatType.EVD]: {
        format: "EVD",
        extension: "evd"
    },
    [FormatType.PDF]: {
        format: "PDF",
        extension: "pdf",
        mimeType: "application/pdf"
    },
    [FormatType.JPG]: {
        format: "JPG",
        extension: "jpg",
        mimeType: "image/jpeg",
    },
    [FormatType.PNG]: {
        format: "PNG",
        extension: "png",
        mimeType: "image/png",
    },
    [FormatType.SHADERUPDATE3D]: {
        format: "3DSHADERUPDATE",
        extension: null,
        mimeType: null
    },
    [FormatType.SVG]: {
        format: "SVG",
        extension: "svg",
        mimeType: "image/svg+xml"
    },
    [FormatType.DDE]: {
        format: "DDE",
        extension: "dde"
    },
    [FormatType.DD3]: {
        format: "DD3",
        extension: "dd3"
    },
    [FormatType.DWG]: {
        format: "DWG",
        extension: "dwg"
    },
    [FormatType.AI]: {
        format: "AI",
        extension: "ai",
    },
    [FormatType.VIEWER]: {
        format: "VIEWER",
        extension: "json",
        mimeType: "application/json"
    },
    [FormatType.USDZ]: {
        format: "USDZ",
        extension: "usdz",
    },
};

function getFormatTypeInfo(formatType: FormatType): FormatTypeInfo {
    return FormatTypeInfoMap[formatType];
}

export {FormatType, getFormatTypeInfo}
