enum OutputType {
    CF2="CF2",
    COLLADA="COLLADA",
    VIEWER_INIT="VIEWER_INIT",
    EDITOR_INIT="EDITOR_INIT",
    DXF="DXF",
    EVD="EVD",
    PDF2D="PDF2D",
    PDF3D="PDF3D",
    PREVIEW2D="PREVIEW2D",
    PREVIEW3D="PREVIEW3D",
    SHADERS="SHADERS",
    SVG="SVG",
    DDE="DDE",
    DD3="DD3",
    DWG="DWG",
    AI="AI",
    BUMP="BUMP",
    USDZ="USDZ"
}

export {OutputType}
