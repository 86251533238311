import type {BleedOperation} from "./BleedOperation";
import type {BleedStyle} from "./BleedStyle";

class ModifyBleed {
    private bleedOperation!: BleedOperation;
    private style!: BleedStyle;
    private bleed!: number;

    public getBleedOperation(): BleedOperation {
        return this.bleedOperation;
    }

    public setBleedOperation(bleedOperation: BleedOperation): void {
        this.bleedOperation = bleedOperation;
    }

    public getStyle(): BleedStyle {
        return this.style;
    }

    public setStyle(style: BleedStyle): void {
        this.style = style;
    }

    public getBleed(): number {
        return this.bleed;
    }

    public setBleed(bleed: number): void {
        this.bleed = bleed;
    }
}

export {ModifyBleed}
