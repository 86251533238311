import {BaseOutput2D} from "./BaseOutput2D";
import type {ColorType} from "../types/ColorType";
import type {YesNo} from "../types/YesNo";
import type {ExportType} from "../types/ExportType";

export abstract class BaseOutput2DCff extends BaseOutput2D {
    public decomposePerfo!: YesNo;
    public decomposeComposites!: YesNo;
    public cutCreaseProcessing!: ExportType;
}
