import {BaseOutput2D} from "./BaseOutput2D";
import type {OneZero} from "../types/OneZero";
import type {YesNo} from "../types/YesNo";
import type {ViewSideType} from "../types/ViewSideType";

export abstract class BaseOutput2DImage extends BaseOutput2D {
    public zoomFit!: OneZero;
    public transparent!: OneZero;
    public w!: number;
    public h!: number;
    public keepAspect!: OneZero;
    public drawDecomposed!: YesNo;
    public drawStyleSymbols!: YesNo;
    public overallML!: boolean;
    public viewSide!: ViewSideType;
    public marginL!: number;
    public marginR!: number;
    public marginT!: number;
    public marginB!: number;
}
