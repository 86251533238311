enum DesignEntity {
    Oneup = "Oneup",
    Layout = "Layout",
    Closed = "Closed",
    ClosedLayout = "ClosedLayout",
    Erected = "Erected",
    ErectedLayout = "ErectedLayout",
    MultiSheet = "MultiSheet"
}

export {DesignEntity}