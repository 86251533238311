class NLSString {
    private text!: string;
    private langid!: string;

    public getText(): string {
        return this.text;
    }

    public setText(text: string): void {
        this.text = text;
    }

    public getLangid(): string {
        return this.langid;
    }

    public setLangid(langid: string): void {
        this.langid = langid;
    }
}

export {NLSString}