import {NLSString} from "./NLSString";
import {Type} from "class-transformer";

class LocalizedName {
    @Type(() => NLSString)
    private localizedNames!: NLSString[];
    private defaultName: string = "";

    public getLocalizedNames(): NLSString[] {
        return this.localizedNames;
    }

    public getDefaultName(): string {
        return this.defaultName;
    }
}

export {LocalizedName}