import {BaseData, ProcessingType, Resolution} from "@engview/lib-icommon";
import {Transform} from "class-transformer";

class EditorLoadData extends BaseData{
    public designUrl!: string;
    public designUuid!: string;
    @Transform(({value}) => {
        const r: Map<ProcessingType, Resolution> = new Map();
        for (let [k, v] of Object.entries(value))
            r.set(ProcessingType[k as keyof typeof ProcessingType], Number(v));
        return r;
    }, { toClassOnly: true })
    public resolutions!: Map<ProcessingType, Resolution>;
}

export {EditorLoadData}