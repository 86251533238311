class InlinedImage {
    // private data: byte[];//TODO
    private data!: string;
    private type!: string;

    public getData(): string {
        return this.data;
    }

    public setData(data: string) {
        this.data = data;
    }

    public getType(): string {
        return this.type;
    }

    public setType(type: string): void {
        this.type = type;
    }
}

export {InlinedImage}