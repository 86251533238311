class rotrect2d {
    private x!: number;
    private y!: number;
    private w!: number;
    private h!: number;
    private a!: number;

    public getX(): number {
        return this.x;
    }

    public setX(x: number): void {
        this.x = x;
    }

    public getY(): number {
        return this.y;
    }

    public setY(y: number): void {
        this.y = y;
    }

    public getW(): number {
        return this.w;
    }

    public setW(w: number): void {
        this.w = w;
    }

    public getH(): number {
        return this.h;
    }

    public setH(h: number) {
        this.h = h;
    }

    public getA(): number {
        return this.a;
    }

    public setA(a: number): void {
        this.a = a;
    }
}

export {rotrect2d}