import {BaseData} from "./BaseData";
import type {Viewer2DData} from "./Viewer2DData";

export class Viewer2DModel extends BaseData {

    public activeIndex: number;
    public data: Viewer2DData;

    constructor(activeIndex: number, data: Viewer2DData) {
        super();
        this.activeIndex = activeIndex;
        this.data = data;
    }
}