import {InlinedImage} from "./InlinedImage";
import {Type} from "class-transformer";

class ArtworContentResource {

    @Type(() => InlinedImage)
    protected inlinedContents!: InlinedImage;
    protected contentsurl!: string;
    protected revision!: string;

    public getInlinedContents(): InlinedImage {
        return this.inlinedContents;
    }

    public setInlinedContents(value: InlinedImage): void {
        this.inlinedContents = value;
    }

    public getContentsurl(): string {
        return this.contentsurl;
    }

    public setContentsurl(value: string): void {
        this.contentsurl = value;
    }

    public getRevision(): string {
        return this.revision;
    }

    public setRevision(value: string): void {
        this.revision = value;
    }
}

export {ArtworContentResource}