enum PlaceholderAlignment {
    HCenterBottom = "HCenterBottom",
    HCenterTop = "HCenterTop",
    HCenterVCenter = "HCenterVCenter",
    LeftBottom = "LeftBottom",
    LeftTop = "LeftTop",
    LeftVCenter = "LeftVCenter",
    RightBottom = "RightBottom",
    RightTop = "RightTop",
    RightVCenter = "RightVCenter"
}

export {PlaceholderAlignment}