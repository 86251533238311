import {BaseOutput3D} from "./BaseOutput3D";
import type {YesNo} from "../types/YesNo";
import type {OneZero} from "../types/OneZero";

export abstract class BaseOutput3DImage extends BaseOutput3D {

    public zoomFit!: YesNo;
    public w!: number;
    public h!: number;
    public keepAspect!: OneZero;
}
