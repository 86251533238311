enum FlipDesignAction {
    /// <summary>
    /// Dont flip the design
    /// </summary>
    None = "None",
    /// <summary>
    /// only this drawing is flipped, leaving all other related elements with their previous state
    /// If a oneup resides on a layout, this action usually 'scrambles' the layout, unless the layout is flipped as well (with SimpleFlip)
    /// </summary>
    Simple = "Simple",
    /// <summary>
    /// Flip this drawing, as well as all related layouts and their related layouts and so on.
    /// When using this flap, it should be set to only one of the drawings in the set of part-layout related drawings, or the actions will cancel each other.
    /// </summary>
    Recursive = "Recursive"
}

export {FlipDesignAction}