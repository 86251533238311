import {GlobalUtil} from "@engview/lib-utils";
import type {ImageElement} from "./ImageElement";
import type {TextElement} from "./TextElement";
import type {IProcessing} from "../../../../../editor/editor/dto/ArtworkPackage";

export type ElementTypes = (ImageElement|TextElement);

export enum ElementType {
    TEXT = "TEXT",
    IMAGE = "IMAGE"
}

export abstract class Element {

    public x: number|null;
    public y: number|null;
    public width: number|null;
    public height: number|null;
    public uid: string;
    public type: ElementType;
    public processing: IProcessing|null = null;

    public exportPreview: HTMLCanvasElement|null = null;

    protected constructor(element: Element);
    protected constructor(type: ElementType, x: number|null, y: number|null, width: number|null, height: number|null, processing: IProcessing|null);
    protected constructor(...arr: any[]) {
        if(arr.length === 1) {
            const element: Element = arr[0] as Element;
            this.type = element.type;
            this.uid = element.uid;
            this.x = element.x;
            this.y = element.y;
            this.width = element.width;
            this.height = element.height;
            this.processing = element.processing;
        }
        else {
            this.type = arr[0];
            this.uid = GlobalUtil.utils.generateUuid4();
            this.x = arr[1];
            this.y = arr[2];
            this.width = arr[3];
            this.height = arr[4];
            this.processing = arr[5];
        }
    }
}