import {DesignParameter} from "./DesignParameter";
import {DesignPlaceholderInfo} from "./DesignPlaceholderInfo";
import {MaterialDefDTO} from "../../cadoverride/utils/MaterialDefDTO";
import {rect2d} from "../utils/rect2d";
import {CostModelInfo} from "../utils/CostModelInfo";
import type {DesignEntity} from "./DesignEntity";
import {FluteDir} from "../utils/FluteDir";
import {DesignMeasureLine} from "./DesignMeasureLine";
import {ViewSide} from "../utils/ViewSide";
import {SheetSizeInfo} from "../utils/SheetSizeInfo";
import {DieboardInfo} from "../utils/DieboardInfo";
import {Type} from "class-transformer";
import {ElementProcessingDTO} from "@engview/lib-icommon";

class DesignDrawing {
    private description!: string;
    @Type(() => DesignParameter)
    private params!: DesignParameter[];
    @Type(() => DesignPlaceholderInfo)
    private placeholders!: DesignPlaceholderInfo[];
    @Type(() => MaterialDefDTO)
    private material!: MaterialDefDTO;
    @Type(() => ElementProcessingDTO)
    private frontBacking!: ElementProcessingDTO;
    @Type(() => ElementProcessingDTO)
    private frontLamination!: ElementProcessingDTO;
    @Type(() => ElementProcessingDTO)
    private rearBacking!: ElementProcessingDTO;
    @Type(() => ElementProcessingDTO)
    private rearLamination!: ElementProcessingDTO;
    @Type(() => rect2d)
    private designartworkrect!: rect2d;
    @Type(() => rect2d)
    private designobjrect!: rect2d;
    @Type(() => rect2d)
    private designoverallrect!: rect2d;
    @Type(() => CostModelInfo)
    private costmodel!: CostModelInfo;
    private id!: number;
    private reqDrawingId!: number;
    private name!: string;
    private type!: DesignEntity;
    private enableFrontBacking!: boolean;
    private enableFrontLamination!: boolean;
    private enableRearBacking!: boolean;
    private enableRearLamination!: boolean;
    private flutedir: FluteDir = FluteDir.FD_unknown;
    @Type(() => DesignMeasureLine)
    private measlines!: DesignMeasureLine[];
    private viewside: ViewSide = ViewSide.DVS_Unknown;
    @Type(() => SheetSizeInfo)
    private sheet!: SheetSizeInfo;
    @Type(() => DieboardInfo)
    private dieboard!: DieboardInfo;

    public getDescription(): string {
        return this.description;
    }

    public setDescription(description: string): void {
        this.description = description;
    }

    public getParams(): DesignParameter[] {
        return this.params;
    }

    public setParams(params: DesignParameter[]): void {
        this.params = params;
    }

    public getPlaceholders(): DesignPlaceholderInfo[] {
        return this.placeholders;
    }

    public setPlaceholders(placeholders: DesignPlaceholderInfo[]): void {
        this.placeholders = placeholders;
    }

    public getMaterial(): MaterialDefDTO {
        return this.material;
    }

    public setMaterial(material: MaterialDefDTO): void {
        this.material = material;
    }

    public getFrontBacking(): ElementProcessingDTO {
        return this.frontBacking;
    }

    public setFrontBacking(frontBacking: ElementProcessingDTO): void {
        this.frontBacking = frontBacking;
    }

    public getFrontLamination(): ElementProcessingDTO {
        return this.frontLamination;
    }

    public setFrontLamination(frontLamination: ElementProcessingDTO): void {
        this.frontLamination = frontLamination;
    }

    public getRearBacking(): ElementProcessingDTO {
        return this.rearBacking;
    }

    public setRearBacking(rearBacking: ElementProcessingDTO): void {
        this.rearBacking = rearBacking;
    }

    public getRearLamination(): ElementProcessingDTO {
        return this.rearLamination;
    }

    public setRearLamination(rearLamination: ElementProcessingDTO): void {
        this.rearLamination = rearLamination;
    }

    public getDesignartworkrect(): rect2d {
        return this.designartworkrect;
    }

    public setDesignartworkrect(designartworkrect: rect2d): void {
        this.designartworkrect = designartworkrect;
    }

    public getDesignobjrect(): rect2d {
        return this.designobjrect;
    }

    public setDesignobjrect(designobjrect: rect2d): void {
        this.designobjrect = designobjrect;
    }

    public getDesignoverallrect(): rect2d {
        return this.designoverallrect;
    }

    public setDesignoverallrect(designoverallrect: rect2d): void {
        this.designoverallrect = designoverallrect;
    }

    public getCostmodel(): CostModelInfo {
        return this.costmodel;
    }

    public setCostmodel(costmodel: CostModelInfo): void {
        this.costmodel = costmodel;
    }

    public getId(): number {
        return this.id;
    }

    public setId(id: number): void {
        this.id = id;
    }

    public getName(): string {
        return this.name;
    }

    public setName(name: string): void {
        this.name = name;
    }

    public getType(): DesignEntity {
        return this.type;
    }

    public setType(type: DesignEntity): void {
        this.type = type;
    }

    public isEnableFrontBacking(): boolean {
        return this.enableFrontBacking;
    }

    public setEnableFrontBacking(enableFrontBacking: boolean): void {
        this.enableFrontBacking = enableFrontBacking;
    }

    public isEnableFrontLamination(): boolean {
        return this.enableFrontLamination;
    }

    public setEnableFrontLamination(enableFrontLamination: boolean): void {
        this.enableFrontLamination = enableFrontLamination;
    }

    public isEnableRearBacking(): boolean {
        return this.enableRearBacking;
    }

    public setEnableRearBacking(enableRearBacking: boolean): void {
        this.enableRearBacking = enableRearBacking;
    }

    public isEnableRearLamination(): boolean {
        return this.enableRearLamination;
    }

    public setEnableRearLamination(enableRearLamination: boolean): void {
        this.enableRearLamination = enableRearLamination;
    }

    public getFlutedir(): FluteDir {
        return this.flutedir;
    }

    public setFlutedir(flutedir: FluteDir): void {
        this.flutedir = flutedir;
    }

    public getMeaslines(): DesignMeasureLine[] {
        return this.measlines;
    }

    public setMeaslines(measlines: DesignMeasureLine[]): void {
        this.measlines = measlines;
    }

    public getViewside(): ViewSide {
        return this.viewside !== null ? this.viewside : ViewSide.DVS_Unknown;
    }

    public setViewside(viewside: ViewSide): void {
        this.viewside = viewside;
    }

    public getReqDrawingId(): number {
        return this.reqDrawingId;
    }

    public setReqDrawingId(reqDrawingId: number): void {
        this.reqDrawingId = reqDrawingId;
    }

    public getDieboard(): DieboardInfo {
        return this.dieboard;
    }

    public setDieboard(dieboard: DieboardInfo): void {
        this.dieboard = dieboard;
    }

    public getSheet(): SheetSizeInfo {
        return this.sheet;
    }

    public setSheet(sheet: SheetSizeInfo): void {
        this.sheet = sheet;
    }

    public clearParamCalcResults(): void {
        if (this.params !== null) {
            this.params.forEach(function (p) {
                p.clearCalcResult();
            });
        }
    }
}

export {DesignDrawing}