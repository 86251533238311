import {PlaceholderAlignment} from "./PlaceholderAlignment";

class SheetSizeInfo {
    private name!: string;
    private width!: number;
    private height!: number;
    private marginL!: number;
    private marginT!: number;
    private marginR!: number;
    private marginB!: number;
    private alignment: PlaceholderAlignment = PlaceholderAlignment.HCenterBottom;
    private alignToMargins!: boolean;
    private trimX!: boolean;
    private trimY!: boolean;

    public copyWithoutDieboard(): SheetSizeInfo {
        const res = new SheetSizeInfo();
        res.name = this.name;
        res.width = this.width;
        res.height = this.height;
        res.marginL = this.marginL;
        res.marginT = this.marginT;
        res.marginR = this.marginR;
        res.marginB = this.marginB;
        res.alignment = this.alignment;
        res.alignToMargins = this.alignToMargins;
        return res;
    }

    public getName(): string {
        return this.name;
    }

    public setName(name: string): void {
        this.name = name;
    }

    public getWidth(): number {
        return this.width;
    }

    public setWidth(width: number): void {
        this.width = width;
    }

    public getHeight(): number {
        return this.height;
    }

    public setHeight(height: number): void {
        this.height = height;
    }

    public getMarginL(): number {
        return this.marginL;
    }

    public setMarginL(marginL: number): void {
        this.marginL = marginL;
    }

    public getMarginT(): number {
        return this.marginT;
    }

    public setMarginT(marginT: number) {
        this.marginT = marginT;
    }

    public getMarginR(): number {
        return this.marginR;
    }

    public setMarginR(marginR: number): void {
        this.marginR = marginR;
    }

    public getMarginB(): number {
        return this.marginB;
    }

    public setMarginB(marginB: number) {
        this.marginB = marginB;
    }

    public getAlignment(): PlaceholderAlignment {
        return this.alignment;
    }

    public setAlignment(alignment: PlaceholderAlignment) {
        this.alignment = alignment;
    }

    public isAlignToMargins(): boolean {
        return this.alignToMargins;
    }

    public setAlignToMargins(alignToMargins: boolean) {
        this.alignToMargins = alignToMargins;
    }

    public isTrimX(): boolean {
        return this.trimX;
    }

    public setTrimX(trimX: boolean): void {
        this.trimX = trimX;
    }

    public isTrimY(): boolean {
        return this.trimY;
    }

    public setTrimY(trimY: boolean): void {
        this.trimY = trimY;
    }
}

export {SheetSizeInfo}