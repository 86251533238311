import {Type} from "class-transformer";
import {DesignParameterValue} from "../design/DesignParameterValue";
import type {BoxSizeMainparams} from "./BoxSizeMainparams";
import type {DesignParameter} from "../design/DesignParameter";

class BoxSizeMainparamsSimple {
    @Type(() => DesignParameterValue)
    private L: DesignParameterValue|null;
    @Type(() => DesignParameterValue)
    private W: DesignParameterValue|null;
    @Type(() => DesignParameterValue)
    private H: DesignParameterValue|null;
    @Type(() => DesignParameterValue)
    private DT: DesignParameterValue|null;

    constructor(value: BoxSizeMainparams) {
        this.L = this.initParam(value.getL());
        this.W = this.initParam(value.getW());
        this.H = this.initParam(value.getH());
        this.DT = this.initParam(value.getDT());
    }

    private initParam(p: DesignParameter): DesignParameterValue|null {
        if(p)
            return new DesignParameterValue(p);
        return null;
    }

    public getL(): DesignParameterValue|null {
        return this.L;
    }

    public setL(l: DesignParameterValue): void {
        this.L = l;
    }

    public getW(): DesignParameterValue|null {
        return this.W;
    }

    public setW(w: DesignParameterValue): void {
        this.W = w;
    }

    public getH(): DesignParameterValue|null {
        return this.H;
    }

    public setH(h: DesignParameterValue): void {
        this.H = h;
    }

    public getDT(): DesignParameterValue|null {
        return this.DT;
    }

    public setDT(dT: DesignParameterValue): void {
        this.DT = dT;
    }

    public asArray(): DesignParameterValue[] {
        const mpValues: DesignParameterValue[] = [];
        const lValue: DesignParameterValue|null = this.getL();
        if(lValue)
            mpValues.push(lValue);
        const wValue: DesignParameterValue|null = this.getW();
        if(wValue)
            mpValues.push(wValue);
        const hValue: DesignParameterValue|null = this.getH();
        if(hValue)
            mpValues.push(hValue);
        const dtValue: DesignParameterValue|null = this.getDT();
        if(dtValue)
            mpValues.push(dtValue);
        return mpValues;
    }

    public hasParam(paramName: string): boolean {
        if (this.L && this.L.getName() === paramName)
            return true;
        if (this.W &&this.W.getName() === paramName)
            return true;
        if (this.H && this.H.getName() === paramName)
            return true;
        if (this.DT && this.DT.getName() === paramName)
            return true;
        return false;
    }
}

export {BoxSizeMainparamsSimple}