export enum ColorType {

    None="None",
    CMYK="CMYK",
    RGB="RGB",
    CMYKWithSpecial="CMYKWithSpecial",
    RGBWithSpecial="RGBWithSpecial",
    Mono="Mono",
    SingleTable="SingleTable",
    Multi="Multi",
    MultiTable="MultiTable"
}
