import { BaseOutput3D } from "./BaseOutput3D";
import { OutputType } from "./OutputType";
import { FormatType } from "./FormatType";
import type {SC} from "../types/SC";
import type {YesNo} from "../types/YesNo";
import type {ViewPointType} from "../types/ViewPointType";

abstract class BaseOutput3DPdf extends BaseOutput3D {

    public disableEmbossTextCombining!: YesNo;
    public phaseFrom!: SC;
    public phaseTo!: SC;
    public viewPoint!: ViewPointType;
    public phaseInit!: SC;
    public showPreview!: YesNo;
    public stillScene!: SC;
    public exportMl!: YesNo;

    constructor(other: BaseOutput3DPdf);
    constructor(type?: OutputType, format?: FormatType, drawingId?: number | null);
    constructor(...arr: any[]) {
        if(arr.length == 0){
            super(OutputType.PDF3D, FormatType.PDF, null);
        }
        else if (arr.length === 1) {
            const other: BaseOutput3DPdf = arr[0];
            super(other);
            this.disableEmbossTextCombining = other.disableEmbossTextCombining;
            this.phaseFrom = other.phaseFrom;
            this.phaseTo = other.phaseTo;
            this.viewPoint = other.viewPoint;
            this.phaseInit = other.phaseInit;
            this.showPreview = other.showPreview;
            this.stillScene = other.stillScene;
            this.exportMl = other.exportMl;
        } else if (arr.length === 3) {
            super(arr[0], arr[1], arr[2]);
        }
    }
}

export { BaseOutput3DPdf };