import {ResourceType} from "./ResourceType";
import type {OwnershipType} from "./OwnershipType";
import {GlobalUtil, IFactory} from "@engview/lib-utils";
import {ClassConstructor, Transform, Type} from "class-transformer";

class ResourceResultDTOFactory implements IFactory<ResourceResultDTO> {
    public getClassFunction(o: any): ClassConstructor<ResourceResultDTO> {
        switch (o.type) {
            case ResourceType.File:
                return FileResourceResultDTO;
            case ResourceType.Directory:
                return DirectoryResourceResultDTO;
            default:
                throw new Error('Invalid ResourceResultDTO type');
        }
    }
}

abstract class ResourceResultDTO {
    public type!: ResourceType;
    public uuid!: string;
    public path!: string;
    public name!: string;
    public temporary!: boolean;
    public secure!: boolean;
    public metadata!: string;
    public ownerUuid!: string;
    public ownerType!: OwnershipType;
    public ott!: string;

    constructor(type: ResourceType, uuid: string, path: string, name: string, temporary: boolean, secure: boolean, metadata: string, ownerUuid: string, ownerType: OwnershipType) {
        this.type = type;
        this.uuid = uuid;
        this.path = path;
        this.name = name;
        this.temporary = temporary;
        this.secure = secure;
        this.metadata = metadata;
        this.ownerUuid = ownerUuid;
        this.ownerType = ownerType;
    }
}

class FileResourceResultDTO extends ResourceResultDTO {

    public mimeType!: string;
    public hash!: string;

    constructor(uuid: string, path: string, name: string, temporary: boolean, secure: boolean, metadata: string, ownerUuid: string, ownerType: OwnershipType, mimeType: string, hash: string) {
        super(ResourceType.File, uuid, path, name, temporary, secure, metadata, ownerUuid, ownerType);
        this.mimeType = mimeType;
        this.hash = hash;
    }
}

class DirectoryResourceResultDTO extends ResourceResultDTO {

    @Type(() => ResourceResultDTO)
    @Transform(({value}) => GlobalUtil.jsonUtil.plainToObj(ResourceResultDTO, value, new ResourceResultDTOFactory()), { toClassOnly: true })
    private resources: ResourceResultDTO[] = [];

    constructor(uuid: string, path: string, name: string, temporary: boolean, secure: boolean, metadata: string, ownerUuid: string, ownerType: OwnershipType, resources: ResourceResultDTO[]) {
        super(ResourceType.Directory, uuid, path, name, temporary, secure, metadata, ownerUuid, ownerType);
        this.resources = resources;
    }
}

export {ResourceResultDTO, FileResourceResultDTO, DirectoryResourceResultDTO, ResourceResultDTOFactory}