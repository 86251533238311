import {BoxSize} from "../utils/BoxSize";
import {BoxSizeMainparams} from "../utils/BoxSizeMainparams";
import {DimType} from "../utils/DimType";
import {FluteDir} from "../utils/FluteDir";
import {Type} from "class-transformer";
import {DesignParameter} from "./DesignParameter";

class DesignBasicInfo {
    @Type(() => BoxSize)
    private scoretoscoreSize!: BoxSize;
    @Type(() => BoxSize)
    private inSize!: BoxSize;
    @Type(() => BoxSize)
    private outSize!: BoxSize;
    @Type(() => BoxSizeMainparams)
    private mainparams!: BoxSizeMainparams;
    private dimtype: DimType = DimType.unspecified;
    private usedtemplateId!: string;
    private bResizable: boolean = true;
    private flutedir: FluteDir = FluteDir.FD_unknown;
    private closertype!: string;
    private mainDrwId!: number;
    private mainDrwIdSpecified!: boolean;

    public getDimtype(): DimType {
        return this.dimtype;
    }

    public setDimtype(dimtype: DimType): void {
        this.dimtype = dimtype;
    }

    public getScoretoscoreSize(): BoxSize {
        return this.scoretoscoreSize;
    }

    public setScoretoscoreSize(scoretoscoreSize: BoxSize): void {
        this.scoretoscoreSize = scoretoscoreSize;
    }

    public getInSize(): BoxSize {
        return this.inSize;
    }

    public setInSize(inSize: BoxSize): void {
        this.inSize = inSize;
    }

    public getOutSize(): BoxSize {
        return this.outSize;
    }

    public setOutSize(outSize: BoxSize): void {
        this.outSize = outSize;
    }

    public getMainparams(): BoxSizeMainparams {
        return this.mainparams;
    }

    public setMainparams(mainparams: BoxSizeMainparams): void {
        this.mainparams = mainparams;
    }

    public getUsedtemplateId(): string {
        return this.usedtemplateId;
    }

    public setUsedtemplateId(usedtemplateId: string): void {
        this.usedtemplateId = usedtemplateId;
    }

    public getFlutedir(): FluteDir {
        return this.flutedir;
    }

    public setFlutedir(flutedir: FluteDir): void {
        this.flutedir = flutedir;
    }

    public getClosertype(): string {
        return this.closertype;
    }

    public setClosertype(closertype: string): void {
        this.closertype = closertype;
    }

    public getMainDrwId(): number {
        return this.mainDrwId;
    }

    public setMainDrwId(mainDrwId: number): void {
        this.mainDrwIdSpecified = true;
        this.mainDrwId = mainDrwId;
    }
    public isbResizable(): boolean {
        return this.bResizable;
    }

    public setbResizable(bResizable: boolean): void {
        this.bResizable = bResizable;
    }

    public isMainDrwIdSpecified(): boolean {
        return this.mainDrwIdSpecified;
    }

    public setMainDrwIdSpecified(mainDrwIdSpecified: boolean): void {
        this.mainDrwIdSpecified = mainDrwIdSpecified;
    }

    public clearParamCalcResults(): void {
        if (this.mainparams != null) {
            this.mainparams.clearParamCalcResults();
        }
    }
}

export {DesignBasicInfo}