import {BaseOutputResponseDTO} from "./helper/BaseOutputResponseDTO";
import {Attachment} from "./cad/utils/Attachment";
import {Type} from "class-transformer";

class TemplateInfoResponseDTO extends BaseOutputResponseDTO {

    @Type(() => Attachment)
    private attachments!: Attachment[];

    public getAttachments(): Attachment[] {
        return this.attachments;
    }

    public setAttachments(attachments: Attachment[]): void {
        this.attachments = attachments;
    }
}

export {TemplateInfoResponseDTO}