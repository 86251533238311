import {OutputType} from "./OutputType";
import {FormatType} from "./FormatType";
import {BaseOutput2DImage} from "./BaseOutput2DImage";
import type {PreviewType} from "./PreviewType";
import type {IOutputRequestPrev} from "./IOutputRequestPrev";

class OutputPreview2D extends BaseOutput2DImage implements IOutputRequestPrev {

    public previewType!: PreviewType;

    constructor();
    constructor(other: OutputPreview2D);
    constructor(drawingId?: number | null);
    constructor(...arr: any[]) {
        if(arr.length == 0){
            super(OutputType.PREVIEW2D, FormatType.PNG, null);
        }
        else if (arr.length === 1) {
            if(arr[0] instanceof OutputPreview2D){
                const other: OutputPreview2D = arr[0];
                super(other);
                this.previewType = other.previewType;
            }
            else{
                super(OutputType.PREVIEW2D, FormatType.PNG, arr[0]);
            }
        }
    }
}

export {OutputPreview2D}
