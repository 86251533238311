import {OutputRequest} from "./OutputRequest";
import type {OneZero} from "../types/OneZero";
import type {Size} from "../types/Size";
import type {Scale} from "../types/Scale";

abstract class BaseOutput2D extends OutputRequest {
    public shotName!: string;
    public exportMl!: OneZero;
    public mlScalingScalingName!: string;
    public mlScalingToSize!: Size;
    public mlScalingFixed!: Scale;
    public mlForceScaling!: boolean;
    public mlNumbersOnly!: boolean;
    public exportStyleSymbols!: boolean;
    public cf2Filters!: string;
    public ddes2Filters!: string;
    public ddes3Filters!: string;
    public epsPDFFilters!: string;
    public epsTemplatePrintsOnly!: boolean;
}

export {BaseOutput2D}
