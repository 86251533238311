import type {ClassConstructor} from 'class-transformer';
import type {IFactory} from "@engview/lib-utils";

class BackgroundFactory implements IFactory<Background> {
    public getClassFunction(o: any): ClassConstructor<Background> {
        switch (o.layoutType) {
            case LayoutType.Color:
                return ColorBackground;
            case LayoutType.FlatImage:
                return FlatImage;
            case LayoutType.EquiRectangular:
                return EquiRectangular;
            default:
                throw new Error('Invalid layout type');
        }
    }
}

enum DataType {
    HDR = "HDR",
    RGBE = "RGBE",
    sRGB = "sRGB"
}

enum LayoutType {
    Color = "Color",
    FlatImage = "FlatImage",
    EquiRectangular = "EquiRectangular"
}

abstract class Background {
    public layoutType!: LayoutType;

    constructor(layoutType: LayoutType) {
        this.layoutType = layoutType;
    }
}

class ColorBackground extends Background {
    public color!: string;
    constructor() {
        super(LayoutType.Color);
    }
}

class EquiRectangular extends Background {
    public dataType!: DataType;
    public imageName!: string;
    public backgroundTextureUrl!: string;
    constructor() {
        super(LayoutType.EquiRectangular);
    }
}

class FlatImage extends Background {
    public dataType!: DataType;
    public imageName!: string;
    public backgroundTextureUrl!: string;
    constructor() {
        super(LayoutType.FlatImage);
    }
}

export {Background, EquiRectangular, ColorBackground, FlatImage, BackgroundFactory, DataType, LayoutType}