import {OutputType} from "./OutputType";
import {FormatType} from "./FormatType";
import {BaseOutput2DImage} from "./BaseOutput2DImage";
import type {YesNo} from "../types/YesNo";
import type {IOutputRequestPrev} from "./IOutputRequestPrev";

class OutputSVG extends BaseOutput2DImage implements IOutputRequestPrev {

    public svgPreset!: string;
    public exportStucturalOnly!: YesNo;
    public convertBezies!: YesNo;
    public exportStyleNames!: boolean;

    constructor();
    constructor(other: OutputSVG);
    constructor(drawingId?: number | null);
    constructor(...arr: any[]) {
        if(arr.length == 0){
            super(OutputType.SVG, FormatType.SVG, null);
        }
        else if (arr.length === 1) {
            if(arr[0] instanceof OutputSVG){
                const other: OutputSVG = arr[0];
                super(other);
                this.svgPreset = other.svgPreset;
            }
            else{
                super(OutputType.SVG, FormatType.SVG, arr[0]);
            }
        }
    }
}

export {OutputSVG}
