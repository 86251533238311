export class InitParams {

    public visible: boolean;
    public useDefaultTextures: boolean = true;

    constructor(visible: boolean);
    constructor(visible: boolean, useDefaultTextures?: boolean);
    constructor(...args: any[]) {
        this.visible = args[0];
        if (args.length === 2 && args[1] !== undefined)
            this.useDefaultTextures = args[1];
    }
}