import {rotrect2d} from "../utils/rotrect2d";
import {TextFontInfo} from "../utils/TextFontInfo";
import {TextInputConstraints} from "../utils/TextInputConstraints";
import type {PlaceholderType} from "../utils/PlaceholderType";
import type {PlaceholderAlignment} from "../utils/PlaceholderAlignment";
import type {PlaceholderFitting} from "../utils/PlaceholderFitting";
import {Type} from "class-transformer";
import {ElementProcessingDTO} from "@engview/lib-icommon";

class DesignPlaceholderInfo {
    private description!: string;
    @Type(() => rotrect2d)
    private designPos!: rotrect2d;
    private strContents!: string;
    @Type(() => ElementProcessingDTO)
    private processes!: ElementProcessingDTO[];
    @Type(() => TextFontInfo)
    private font!: TextFontInfo;
    @Type(() => TextInputConstraints)
    private constraints!: TextInputConstraints;
    private id!: string;
    private isPlaceholder!: boolean;
    private name!: string;
    private type!: PlaceholderType;
    private alignment!: PlaceholderAlignment;
    private frontSide!: boolean;
    private canDeleteObject!: boolean;
    private canChangeContent!: boolean;
    private canChangeFont!: boolean;
    private canChangePosition!: boolean;
    private canChangeAllignment!: boolean;
    private canChangeProcessing!: boolean;
    private canChangeColor!: boolean;
    private fitting!: PlaceholderFitting;
    private color!: string;
    private bgcolor!: string;
    private imagecontentcid!: string;

    public getDescription(): string {
        return this.description;
    }

    public setDescription(description: string): void {
        this.description = description;
    }

    public getDesignPos(): rotrect2d {
        return this.designPos;
    }

    public setDesignPos(designPos: rotrect2d): void {
        this.designPos = designPos;
    }

    public getStrContents(): string {
        return this.strContents;
    }

    public setStrContents(strContents: string): void {
        this.strContents = strContents;
    }

    public getProcesses(): ElementProcessingDTO[] {
        return this.processes;
    }

    public setProcesses(processes: ElementProcessingDTO[]): void {
        this.processes = processes;
    }

    public getFont(): TextFontInfo {
        return this.font;
    }

    public setFont(font: TextFontInfo): void {
        this.font = font;
    }

    public getConstraints(): TextInputConstraints {
        return this.constraints;
    }

    public setConstraints(constraints: TextInputConstraints): void {
        this.constraints = constraints;
    }

    public getId(): string {
        return this.id;
    }

    public setId(id: string): void {
        this.id = id;
    }

    public getIsPlaceholder(): boolean {
        return this.isPlaceholder;
    }

    public setIsPlaceholder(isPlaceholder: boolean): void {
        this.isPlaceholder = isPlaceholder;
    }

    public getName(): string {
        return this.name;
    }

    public setName(name: string): void {
        this.name = name;
    }

    public getType(): PlaceholderType {
        return this.type;
    }

    public setType(type: PlaceholderType): void {
        this.type = type;
    }

    public getAlignment(): PlaceholderAlignment {
        return this.alignment;
    }

    public setAlignment(alignment: PlaceholderAlignment): void {
        this.alignment = alignment;
    }

    public isFrontSide(): boolean {
        return this.frontSide;
    }

    public setFrontSide(frontSide: boolean): void {
        this.frontSide = frontSide;
    }

    public isCanDeleteObject(): boolean {
        return this.canDeleteObject;
    }

    public setCanDeleteObject(canDeleteObject: boolean): void {
        this.canDeleteObject = canDeleteObject;
    }

    public isCanChangeContent(): boolean {
        return this.canChangeContent;
    }

    public setCanChangeContent(canChangeContent: boolean): void {
        this.canChangeContent = canChangeContent;
    }

    public isCanChangePosition(): boolean {
        return this.canChangePosition;
    }

    public setCanChangePosition(canChangePosition: boolean): void {
        this.canChangePosition = canChangePosition;
    }

    public isCanChangeAllignment(): boolean {
        return this.canChangeAllignment;
    }

    public setCanChangeAllignment(canChangeAllignment: boolean): void {
        this.canChangeAllignment = canChangeAllignment;
    }

    public isCanChangeProcessing(): boolean {
        return this.canChangeProcessing;
    }

    public setCanChangeProcessing(canChangeProcessing: boolean): void {
        this.canChangeProcessing = canChangeProcessing;
    }

    public isCanChangeColor(): boolean {
        return this.canChangeColor;
    }

    public setCanChangeColor(canChangecolor: boolean): void {
        this.canChangeColor = canChangecolor;
    }

    public getFitting(): PlaceholderFitting {
        return this.fitting;
    }

    public setFitting(fitting: PlaceholderFitting): void {
        this.fitting = fitting;
    }

    public getColor(): string {
        return this.color;
    }

    public setColor(color: string): void {
        this.color = color;
    }

    public getBgcolor(): string {
        return this.bgcolor;
    }

    public setBgcolor(bgcolor: string): void {
        this.bgcolor = bgcolor;
    }

    public getImagecontentcid(): string {
        return this.imagecontentcid;
    }

    public setImagecontentcid(imagecontentcid: string): void {
        this.imagecontentcid = imagecontentcid;
    }

    public isCanChangeFont(): boolean {
        return this.canChangeFont;
    }

    public setCanChangeFont(canChangeFont: boolean): void {
        this.canChangeFont = canChangeFont;
    }
}

export {DesignPlaceholderInfo}