import {Type} from "class-transformer";
import {BaseData} from "./BaseData";
import {Animation} from "../ColladaPackage";

class ViewerData extends BaseData {
    @Type(() => Animation)
    public animations!: Animation;
    public colladaUrl!: string;
    public usdzUrl!: string;
    public colladaText!: string;
}

export {ViewerData}