import {OutputResponse, OutputResponseFactory} from "../cadoverride/utils/OutputResponsePackage";
import {BaseResponse} from "../cad/calls/BaseResponse";
import {ViewerData} from "@engview/lib-icommon";
import {Transform, Type} from "class-transformer";
import {EditorData} from "../../../dto/EditorData";
import {GlobalUtil} from "@engview/lib-utils";

abstract class BaseOutputResponseDTO extends BaseResponse {

    @Type(() => ViewerData)
    private viewerData!: ViewerData;
    @Type(() => EditorData)
    private editorData!: EditorData;
    @Transform(({value}) => GlobalUtil.jsonUtil.plainToArray(OutputResponse, value, new OutputResponseFactory()), { toClassOnly: true })
    private outputResponses: OutputResponse[] = [];

    public getViewerData(): ViewerData {
        return this.viewerData;
    }

    public setViewerData(viewerData: ViewerData): void {
        this.viewerData = viewerData;
    }

    public getEditorData(): EditorData {
        return this.editorData;
    }

    public setEditorData(editorData: EditorData): void {
        this.editorData = editorData;
    }

    public getOutputResponses(): OutputResponse[] {
        return this.outputResponses;
    }

    public setOutputResponses(outputResponses: OutputResponse[]): void {
        this.outputResponses = outputResponses;
    }
}

export {BaseOutputResponseDTO}
