import {DesignParameterValue} from "../design/DesignParameterValue";
import {Type} from "class-transformer";

class CostModInValues {
    @Type(() => DesignParameterValue)
    private inParams: DesignParameterValue[] = [];
    private id!: string;

    public getInParams(): DesignParameterValue[] {
        return this.inParams;
    }

    public setInParams(inParams: DesignParameterValue[]): void {
        this.inParams = inParams;
    }

    public getId(): string {
        return this.id;
    }

    public setId(id: string) {
        this.id = id;
    }
}

export {CostModInValues}