import type {OwnershipType} from "./OwnershipType";
import {ResourceType} from "./ResourceType";
import type {IFactory} from "@engview/lib-utils";
import type {ClassConstructor} from "class-transformer";

class ResourceDTOFactory implements IFactory<ResourceDTO> {
    public getClassFunction(o: any): ClassConstructor<ResourceDTO> {
        switch (o.type) {
            case ResourceType.File:
                return FileResourceDTO;
            case ResourceType.Directory:
                return DirectoryResourceDTO;
            default:
                throw new Error('Invalid ResourceDTO type');
        }
    }
}

abstract class ResourceDTO {

    public directoryUuid: string|null|undefined;
    public name: string|null|undefined;
    //If true verify access with the token, if not return it to whoever want it.
    public type: ResourceType;
    public ownershipType: OwnershipType;
    public temporary: boolean;
    public metadata: string|null|undefined;
    public secure: boolean;
    public resourceUsers!: string[];

    constructor(type: ResourceType, directoryUuid: string|null|undefined, name: string|null|undefined, ownershipType: OwnershipType, temporary: boolean, metadata: string|null|undefined, secure: boolean) {
        this.directoryUuid = directoryUuid;
        this.name = name;
        this.type = type;
        this.ownershipType = ownershipType;
        this.temporary = temporary;
        this.metadata = metadata;
        this.secure = secure;
    }
}

class FileResourceDTO extends ResourceDTO {

    public mimeType: string;

    constructor(mimeType: string, directoryUuid: string|null, name: string|null, ownershipType: OwnershipType, temporary: boolean, metadata: string|null, secure: boolean) {
        super(ResourceType.File, directoryUuid, name, ownershipType, temporary, metadata, secure);
        this.mimeType = mimeType;
    }
}

class DirectoryResourceDTO extends ResourceDTO {

    constructor(directoryUuid: string, name: string, ownershipType: OwnershipType, temporary: boolean, metadata: string, secure: boolean) {
        super(ResourceType.Directory, directoryUuid, name, ownershipType, temporary, metadata, secure);
    }
}

export {ResourceDTO, FileResourceDTO,  DirectoryResourceDTO, ResourceDTOFactory}