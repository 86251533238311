import {rect2d} from "./rect2d";
import {Type} from "class-transformer";
import {SamplerChannelId} from "@engview/lib-icommon";

class DrawingArtworkContentChannel {

    @Type(() => rect2d)
    private position!: rect2d;
    private channel!: SamplerChannelId;
    private layeruid!: string;

    public getPosition(): rect2d {
        return this.position;
    }

    public setPosition(position: rect2d): void {
        this.position = position;
    }

    public getChannel(): SamplerChannelId {
        return this.channel;
    }

    public setChannel(channel: SamplerChannelId): void {
        this.channel = channel;
    }

    public getLayeruid(): string {
        return this.layeruid;
    }

    public setLayeruid(layeruid: string): void {
        this.layeruid = layeruid;
    }
}

export {DrawingArtworkContentChannel}