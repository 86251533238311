import {ArtworContentResource} from "./ArtworContentResource";
import {DrawingArtworkContentChannel} from "./DrawingArtworkContentChannel";
import {Type} from "class-transformer";

class DrawingArtworkContent extends ArtworContentResource{

    @Type(() => DrawingArtworkContentChannel)
    protected channels!: DrawingArtworkContentChannel[];
    private contentUid!: string;

    public getChannels(): DrawingArtworkContentChannel[] {
        return this.channels;
    }

    public setChannels(channels: DrawingArtworkContentChannel[]): void {
        this.channels = channels;
    }

    public getContentUid(): string {
        return this.contentUid;
    }

    public setContentUid(value: string) {
        this.contentUid = value;
    }
}

export {DrawingArtworkContent}