class Result {
	private errocde!: number;
	private errortext!: string;

	public getErrorCode(): number {
		return this.errocde;
	}

	public setErrorCode(errorCode: number): void {
		this.errocde = errorCode;
	}

	public getErrorText(): string {
		return this.errortext;
	}

	public setErrorText(errorText: string): void {
		this.errortext = errorText;
	}
}

export {Result}