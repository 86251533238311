import {DesignParameter} from "./DesignParameter";

class DesignParameterValue {
    private name!: string;
    private expression!: string;

    constructor();
    constructor(param: DesignParameter);
    constructor(param: DesignParameterValue);
    constructor(name: string, expression: string)
    constructor(...arr: any[]) {
        if (arr.length == 1) {
            if(arr[0] instanceof DesignParameter){
                const param: DesignParameter = arr[0] as DesignParameter;
                this.name = param.getName();
                this.expression = param.getExpression();
            }
            else if(arr[0] instanceof DesignParameterValue){
                const param: DesignParameterValue = arr[0] as DesignParameterValue;
                this.name = param.getName();
                this.expression = param.getExpression();
            }
        }
        if (arr.length == 2) {
            this.name = arr[0];
            this.expression = arr[1];
        }
    }

    public getName() : string {
        return this.name;
    }

    public setName(name: string): void {
        this.name = name;
    }

    public getExpression(): string {
        return this.expression;
    }

    public setExpression(expression: string): void {
        this.expression = expression;
    }
}

export {DesignParameterValue}