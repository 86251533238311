import {DesignParameter} from "../design/DesignParameter";
import {NLSString} from "./NLSString";
import {Type} from "class-transformer";

class CostModelInfo {
    private id!: string;
    private name!: string;
    @Type(() => DesignParameter)
    private inParams!: DesignParameter[];
    @Type(() => DesignParameter)
    private outParams!: DesignParameter[];
    @Type(() => NLSString)
    private description!: NLSString[];

    public getId(): string {
        return this.id;
    }

    public setId(id: string): void {
        this.id = id;
    }

    public getName(): string {
        return this.name;
    }

    public setName(name: string): void {
        this.name = name;
    }

    public getInParams(): DesignParameter[] {
        return this.inParams;
    }

    public setInParams(inParams: DesignParameter[]): void {
        this.inParams = inParams;
    }

    public getOutParams(): DesignParameter[] {
        return this.outParams;
    }

    public setOutParams(outParams: DesignParameter[]): void {
        this.outParams = outParams;
    }

    public getDescription(): NLSString[] {
        return this.description;
    }

    public setDescription(description: NLSString[]): void {
        this.description = description;
    }

    public getOutParam(name: string): DesignParameter | null {
        if (this.outParams !== null) {
            for (let i = 0; i < this.outParams.length; i++) {
                const param: DesignParameter = this.outParams[i];
                if(param.getName() === name) {
                    return param;
                }
            }
        }
        return null;
    }
}

export {CostModelInfo}