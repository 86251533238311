enum DrawingActionType {
    /// <summary>
    /// No action (or, call it null if you like to). The default, if not hing more specific is defined
    /// </summary>
    None = "None",
    /// <summary>
    /// Copy the drawing (preserving its type)
    /// </summary>
    Copy = "Copy",
    /// <summary>
    /// pretty much like copy, but if the source is layout, the copy will be simple 1up drawing
    /// </summary>
    CopyAs1up = "CopyAs1up",
    /// <summary>
    /// Deletes the specified drawing. If 1up present on layout, its instances on the layout will be delete
    /// </summary>
    Delete = "Delete",
    /// <summary>
    /// if the drawing to delete represents 1up placed on some layout(s), the parts will be converted to gorup objects (thus visually preserving the layout)
    /// </summary>
    Delete1upAndCopyParts = "Delete1upAndCopyParts",
    /// <summary>
    /// Deletes from the drawaing (presumably, layout) any objects which are 'cutting' or 'creasing', thus getting a 'clean' layout.
    /// </summary>
    ScrubSctructObjects = "ScrubSctructObjects"
}

export {DrawingActionType}